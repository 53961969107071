import React, { useState } from 'react';
import {
  Container, Grid, Typography, FormControl, Select, MenuItem, InputLabel, Button, Box, Paper
} from '@mui/material';
import Sidebar from './Sidebar';
import { Bar, Pie, Radar } from 'react-chartjs-2';
import 'chart.js/auto'; // Importa automaticamente os componentes necessários do Chart.js
import './SupervisaoDashboard.css'; // Importa o arquivo CSS aqui

const SupervisaoDashboard = () => {
  const [periodo, setPeriodo] = useState('');
  const [opcaoPesquisa, setOpcaoPesquisa] = useState('');
  const [filtroAdicional, setFiltroAdicional] = useState('');

  const handlePeriodoChange = (event) => {
    setPeriodo(event.target.value);
  };

  const handleOpcaoPesquisaChange = (event) => {
    setOpcaoPesquisa(event.target.value);
  };

  const handleFiltroAdicionalChange = (event) => {
    setFiltroAdicional(event.target.value);
  };

  const barChartData = {
    labels: ['Dentro do Prazo', 'Fora do Prazo Não Sujeita a Multa', 'Fora do Prazo Sujeita a Multa'],
    datasets: [
      {
        label: 'Resultado Até a Data de Entrega',
        data: [10, 5, 2],
        backgroundColor: ['#4caf50', '#ffeb3b', '#f44336'],
      },
      {
        label: 'Resultado da Data de Entrega até a Data de Vencimento',
        data: [8, 4, 3],
        backgroundColor: ['#2196f3', '#ff9800', '#9c27b0'],
      },
      {
        label: 'Resultado Após a Data de Vencimento',
        data: [6, 3, 4],
        backgroundColor: ['#3f51b5', '#ff5722', '#00bcd4'],
      },
    ],
  };

  const pieChartDataStatus = {
    labels: ['Não Iniciadas', 'Iniciadas', 'Concluídas', 'Suspensas', 'Não Aplicável'],
    datasets: [
      {
        label: 'Status das Tarefas',
        data: [15, 10, 20, 5, 2],
        backgroundColor: ['#ff5722', '#ff9800', '#4caf50', '#9c27b0', '#9e9e9e'],
      },
    ],
  };

  const pieChartDataTotal = {
    labels: ['Dentro do Prazo', 'Fora do Prazo Não Sujeita a Multa', 'Fora do Prazo Sujeita a Multa'],
    datasets: [
      {
        label: 'Total de Tarefas',
        data: [30, 12, 8],
        backgroundColor: ['#4caf50', '#ffeb3b', '#f44336'],
      },
    ],
  };

  const radarChartData = {
    labels: ['Qualidade', 'Velocidade', 'Precisão', 'Eficiência', 'Satisfação'],
    datasets: [
      {
        label: 'Performance',
        data: [4, 3, 5, 2, 4],
        backgroundColor: 'rgba(34, 202, 236, .2)',
        borderColor: 'rgba(34, 202, 236, 1)',
        pointBackgroundColor: 'rgba(34, 202, 236, 1)',
      },
    ],
  };

  return (
    <Box className="supervisao-dashboard-container">
      <Sidebar />
      <Box className="supervisao-dashboard-content">
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom className="supervisao-dashboard-title">
            Supervisão - Dashboard
          </Typography>

          {/* Seção de Filtros */}
          <Grid container spacing={3} className="supervisao-dashboard-filters">
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth className="supervisao-dashboard-form-control">
                <InputLabel>Período</InputLabel>
                <Select value={periodo} onChange={handlePeriodoChange}>
                  <MenuItem value="este_mes">Este Mês</MenuItem>
                  <MenuItem value="mes_passado">Mês Passado</MenuItem>
                  <MenuItem value="proximo_mes">Próximo Mês</MenuItem>
                  <MenuItem value="custom">Selecionar Período</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth className="supervisao-dashboard-form-control">
                <InputLabel>Opções de Pesquisa</InputLabel>
                <Select value={opcaoPesquisa} onChange={handleOpcaoPesquisaChange}>
                  <MenuItem value="obrigacoes">Obrigações</MenuItem>
                  <MenuItem value="ordem_servico">Ordem de Serviço</MenuItem>
                  <MenuItem value="controle">Controle</MenuItem>
                  <MenuItem value="todos">Todos</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth className="supervisao-dashboard-form-control">
                <InputLabel>Filtros Adicionais</InputLabel>
                <Select value={filtroAdicional} onChange={handleFiltroAdicionalChange}>
                  <MenuItem value="clientes">Clientes</MenuItem>
                  <MenuItem value="departamentos">Departamentos</MenuItem>
                  <MenuItem value="usuarios">Usuários</MenuItem>
                  <MenuItem value="grupo_cliente">Grupo de Cliente</MenuItem>
                  <MenuItem value="escritorio">Escritório</MenuItem>
                  <MenuItem value="tipo_cliente">Tipo de Cliente</MenuItem>
                  <MenuItem value="tags">Tags</MenuItem>
                  <MenuItem value="tipo_servico">Tipo de Serviço</MenuItem>
                  <MenuItem value="grupo_servico">Grupo de Serviço</MenuItem>
                  <MenuItem value="obrigacao">Obrigação</MenuItem>
                  <MenuItem value="ordem_servico">Ordem de Serviço</MenuItem>
                  <MenuItem value="controles">Controles</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button variant="contained" fullWidth className="supervisao-dashboard-button">
                Aplicar Filtros
              </Button>
            </Grid>
          </Grid>

          {/* Seção de Gráficos */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box className="supervisao-dashboard-card">
                <Typography variant="h6" gutterBottom>
                  Status das Tarefas
                </Typography>
                <div className="chart-container">
                  <Pie data={pieChartDataStatus} options={{ responsive: true, maintainAspectRatio: false }} />
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="supervisao-dashboard-card">
                <Typography variant="h6" gutterBottom>
                  Resultados por Data de Entrega
                </Typography>
                <div className="chart-container">
                  <Bar data={barChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="supervisao-dashboard-card">
                <Typography variant="h6" gutterBottom>
                  Resultados até a Data de Vencimento
                </Typography>
                <div className="chart-container">
                  <Bar data={barChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="supervisao-dashboard-card">
                <Typography variant="h6" gutterBottom>
                  Total de Tarefas
                </Typography>
                <div className="chart-container">
                  <Pie data={pieChartDataTotal} options={{ responsive: true, maintainAspectRatio: false }} />
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box className="supervisao-dashboard-card">
                <Typography variant="h6" gutterBottom>
                  Gráfico de Performance
                </Typography>
                <div className="chart-container">
                  <Radar data={radarChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default SupervisaoDashboard;
