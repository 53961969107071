import React, { useMemo, useState } from 'react';
import { Box, Button, Typography, IconButton, Tooltip, Container, Modal, TextField, MenuItem } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import FilterListIcon from '@mui/icons-material/FilterList';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer } from 'recharts';
// import './ContasPagarPage.css'; // Removida ou comentada a linha que importava o CSS

const initialContasPagar = [
  { id: 1, fornecedor: 'Fornecedor A', valor: 3000.00, vencimento: '12/09/2024', status: 'Pendente' },
  { id: 2, fornecedor: 'Fornecedor B', valor: 4500.00, vencimento: '22/09/2024', status: 'Pago' },
  { id: 3, fornecedor: 'Fornecedor C', valor: 2800.00, vencimento: '30/09/2024', status: 'Pendente' },
  { id: 4, fornecedor: 'Fornecedor D', valor: 1500.00, vencimento: '15/10/2024', status: 'Pago' },
];

const ContasPagarPage = () => {
  const [contasPagar, setContasPagar] = useState(initialContasPagar);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newConta, setNewConta] = useState({
    fornecedor: '',
    valor: '',
    vencimento: '',
    status: '',
  });

  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      { accessorKey: 'fornecedor', header: 'Fornecedor', size: 200 },
      { accessorKey: 'valor', header: 'Valor', size: 150, Cell: ({ cell }) => `R$ ${cell.getValue().toFixed(2)}` },
      { accessorKey: 'vencimento', header: 'Vencimento', size: 150 },
      { accessorKey: 'status', header: 'Status', size: 150 },
    ],
    []
  );

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewConta((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const id = contasPagar.length + 1;
    const novaConta = { id, ...newConta, valor: parseFloat(newConta.valor) };
    setContasPagar((prev) => [...prev, novaConta]);
    handleCloseModal();
  };

  const dataForChart = useMemo(() => {
    return contasPagar.map(conta => ({
      nome: conta.fornecedor,
      valor: conta.valor,
      vencimento: conta.vencimento,
    }));
  }, [contasPagar]);

  return (
    <Box className="contas-pagar-container" sx={{ display: 'flex' }}>
      <Sidebar />
      <Box className="contas-pagar-content" sx={{ flexGrow: 1, p: 3 }}>
        <Container>
          <Typography variant="h4" gutterBottom className="contas-pagar-title" sx={{ fontWeight: 'bold', mb: 4 }}>
            Contas a Pagar
          </Typography>
          
          {/* Gráfico de Barras */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Valores por Fornecedor
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={dataForChart}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="nome" />
                <YAxis />
                <RechartsTooltip />
                <Legend />
                <Bar dataKey="valor" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </Box>

          {/* Gráfico de Linhas */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Vencimentos por Data
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={dataForChart}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="vencimento" />
                <YAxis />
                <RechartsTooltip />
                <Legend />
                <Line type="monotone" dataKey="valor" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </Box>

          <Box className="contas-pagar-actions" sx={{ mb: 4, display: 'flex', justifyContent: 'space-between' }}>
            <Tooltip title="Filtros">
              <IconButton>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
            >
              Nova Conta
            </Button>
          </Box>

          <Box className="contas-pagar-table" sx={{ mb: 4 }}>
            <MaterialReactTable
              columns={columns}
              data={contasPagar}
              initialState={{ density: 'comfortable' }}
              muiTableContainerProps={{ sx: { maxHeight: 'calc(100vh - 200px)' } }}
              muiTableBodyCellProps={{ sx: { p: '8px' } }}
              enableRowSelection
              enableColumnDragging
              enableColumnResizing
              enableStickyHeader
              enableFullScreenToggle={false}
              localization={{
                searchPlaceholder: 'Pesquisar...',
              }}
              muiSearchTextFieldProps={{
                variant: 'outlined',
                placeholder: 'Pesquisar Contas a Pagar',
              }}
            />
          </Box>
        </Container>
      </Box>

      {/* Modal para adicionar nova conta a pagar */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          borderRadius: '8px',
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" gutterBottom>
            Adicionar Nova Conta a Pagar
          </Typography>
          <TextField
            label="Fornecedor"
            name="fornecedor"
            value={newConta.fornecedor}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Valor"
            name="valor"
            type="number"
            value={newConta.valor}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Data de Vencimento"
            name="vencimento"
            type="date"
            value={newConta.vencimento}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Status"
            name="status"
            select
            value={newConta.status}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            <MenuItem value="Pendente">Pendente</MenuItem>
            <MenuItem value="Pago">Pago</MenuItem>
          </TextField>
          <Box sx={{ mt: 2, textAlign: 'right' }}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Adicionar
            </Button>
            <Button variant="text" color="secondary" onClick={handleCloseModal} sx={{ ml: 2 }}>
              Cancelar
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ContasPagarPage;
