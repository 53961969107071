import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse, IconButton, Divider, Modal, Box, Typography, Button, TextField
} from '@mui/material';
import {
  Dashboard as DashboardIcon, Business as BusinessIcon, Favorite as FavoriteIcon, Assignment as AssignmentIcon,
  ListAlt as ListAltIcon, Settings as SettingsIcon, ExpandLess, ExpandMore, 
  Work as WorkIcon, Star as StarIcon, FileCopy as FileCopyIcon, Group as GroupIcon, 
  AccessTime as AccessTimeIcon, CalendarToday as CalendarIcon, Email as EmailIcon,
  WhatsApp as WhatsAppIcon, GroupWork as GroupWorkIcon, DoneAll as DoneAllIcon, 
  Delete as DeleteIcon, Extension as ExtensionIcon, Menu as MenuIcon,
  Folder as FolderIcon, Book as BookIcon, Message as MessageIcon, StarBorder as StarBorderIcon,
  ExitToApp as ExitToAppIcon, AutoFixHigh as AutoFixHighIcon  // Ícone para Automações
} from '@mui/icons-material';
import './Sidebar.css';
import logo from './bg_logo_branca.png';


const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);  // Controla a abertura/fechamento do menu
  const [isConfigOpen, setIsConfigOpen] = useState(false);  // Controla o submenu "Configurações"
  const [isAcoesEmLoteOpen, setIsAcoesEmLoteOpen] = useState(false);  // Controla o submenu "Ações em Lote"
  const [isModalOpen, setIsModalOpen] = useState(false); // Controla a abertura do modal de avaliação
  const [rating, setRating] = useState(0); // Controla a nota selecionada
  const [feedback, setFeedback] = useState(''); // Controla o feedback do usuário
  const [feedbackPlaceholder, setFeedbackPlaceholder] = useState(''); // Placeholder para a caixa de texto
  const [showFeedbackField, setShowFeedbackField] = useState(false); // Controla a exibição da caixa de texto
  const [isFinanceOpen, setIsFinanceOpen] = useState(false);  // Novo estado para o submenu Financeiro
  const [isAutomacoesOpen, setIsAutomacoesOpen] = useState(false);  // Novo estado para o submenu Automações


  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleConfigClick = () => {
    setIsConfigOpen(!isConfigOpen);
  };

  const getEmoji = (rating) => {
    switch (rating) {
      case 1: return '😞'; // Muito triste
      case 2: return '😟'; // Triste
      case 3: return '😕'; // Decepcionado
      case 4: return '😐'; // Neutro
      case 5: return '🙂'; // Levemente feliz
      case 6: return '😊'; // Feliz
      case 7: return '😀'; // Muito feliz
      case 8: return '😁'; // Radiante
      case 9: return '😄'; // Muito Radiante
      case 10: return '😍'; // Extremamente feliz
      default: return '😊'; // Emoji padrão
    }
  };

  const handleAcoesEmLoteClick = () => {
    setIsAcoesEmLoteOpen(!isAcoesEmLoteOpen);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleFinanceClick = () => {
    setIsFinanceOpen(!isFinanceOpen);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };
  const handleAutomacoesClick = () => {
    setIsAutomacoesOpen(!isAutomacoesOpen);
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  useEffect(() => {
    if (rating >= 1 && rating <= 8) {
      setFeedbackPlaceholder('O que faltou para atingirmos a nota máxima?');
      setShowFeedbackField(true);
    } else if (rating === 9) {
      setFeedbackPlaceholder('Gostaria de nos enviar algum comentário ou elogio?');
      setShowFeedbackField(true);
    } else if (rating === 10) {
      setShowFeedbackField(false);
    }
  }, [rating]);

  const handleSubmit = () => {
    // Aqui você pode enviar a avaliação para o servidor ou processá-la conforme necessário
    console.log('Avaliação:', rating);
    console.log('Feedback:', feedback);
    handleCloseModal(); // Fecha o modal após enviar a avaliação
  };

  return (
    <>
      <Drawer
        variant="permanent"
        className={`sidebar ${isOpen ? 'open' : 'closed'}`}
        sx={{
          width: isOpen ? 240 : 80,  // Ajuste da largura quando aberto/fechado
          transition: 'width 0.3s ease',
          '& .MuiDrawer-paper': {
            backgroundColor: '#2c3e50', // Cor de fundo aplicada ao papel do Drawer
            color: '#ecf0f1',
            width: isOpen ? 240 : 80,
            overflowX: 'hidden',
          },
        }}
      >
        <div className="logo-container" style={{ textAlign: 'center', padding: '10px 0' }}>
          <IconButton onClick={toggleSidebar} sx={{ color: '#ecf0f1' }}>
            <MenuIcon />
          </IconButton>
          {isOpen && <img src={logo} alt="Logo" className="logo" style={{ width: '50%', margin: '20px auto' }} />}
        </div>
        <Divider sx={{ borderColor: '#34495e' }} />
        <List className="sidebar-list">
         
        
          <ListItem button onClick={() => navigate('/dashboard')}>
            <ListItemIcon><DashboardIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
            {isOpen && <ListItemText primary="Dashboard" />}
          </ListItem>
          <ListItem button onClick={() => navigate('/empresas')}>
            <ListItemIcon><BusinessIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
            {isOpen && <ListItemText primary="Clientes" />}
          </ListItem>
          <ListItem button onClick={() => navigate('/obrigacoes')}>
            <ListItemIcon><FavoriteIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
            {isOpen && <ListItemText primary="Obrigações" />}
          </ListItem>
          
          <ListItem button onClick={() => navigate('/OrdemServico')}>
            <ListItemIcon><ListAltIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
            {isOpen && <ListItemText primary="Ordem de Serviço" />}
          </ListItem>
          
         
          <ListItem button onClick={() => navigate('/controles')}>
            <ListItemIcon><SettingsIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
            {isOpen && <ListItemText primary="Controles" />}
          </ListItem>
          <ListItem button onClick={() => navigate('/Leads')}>
            <ListItemIcon><WorkIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
            {isOpen && <ListItemText primary="Leads" />}
          </ListItem>
          
           {/* Submenu Financeiro */}
           <ListItem button onClick={handleFinanceClick}>
            <ListItemIcon><FileCopyIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
            {isOpen && <ListItemText primary="Financeiro" />}
            {isOpen && (isFinanceOpen ? <ExpandLess sx={{ color: '#ecf0f1' }} /> : <ExpandMore sx={{ color: '#ecf0f1' }} />)}
          </ListItem>
          <Collapse in={isFinanceOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/ContasPagarPage')}>
                <ListItemIcon><FileCopyIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Contas a Pagar" />}
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/ContasReceberPage')}>
                <ListItemIcon><FileCopyIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Contas a Receber" />}
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/FluxoCaixaPage')}>
                <ListItemIcon><FileCopyIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Fluxo de Caixa" />}
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/cobranca-recorrente')}>
                <ListItemIcon><FileCopyIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Cobrança Recorrente" />}
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/contratos')}>
                <ListItemIcon><FileCopyIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Contratos" />}
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => navigate('/DocumentManager')}>
            <ListItemIcon><FolderIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
            {isOpen && <ListItemText primary="Arquivos" />}
          </ListItem>
          <ListItem button onClick={() => navigate('/Faq')}>
            <ListItemIcon><BookIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
            {isOpen && <ListItemText primary="Base de Conhecimento" />}
          </ListItem>
         
          <ListItem button onClick={() => navigate('/Treinamento')}>
            <ListItemIcon><ExtensionIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
            {isOpen && <ListItemText primary="Treinamentos" />}
          </ListItem>
          
          <ListItem button onClick={() => navigate('/Messenger')}>
            <ListItemIcon><MessageIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
            {isOpen && <ListItemText primary="Comunicador" />}
          </ListItem>

         {/* Submenu Automações */}
         <ListItem button onClick={handleAutomacoesClick}>
            <ListItemIcon><AutoFixHighIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
            {isOpen && <ListItemText primary="Automações" />}
            {isOpen && (isAutomacoesOpen ? <ExpandLess sx={{ color: '#ecf0f1' }} /> : <ExpandMore sx={{ color: '#ecf0f1' }} />)}
          </ListItem>
          <Collapse in={isAutomacoesOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/gerar-obrigacoes-servico')}>
                <ListItemIcon><AssignmentIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Gerar obrigações por serviço" />}
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/gerar-obrigacoes-cliente')}>
                <ListItemIcon><AssignmentIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Gerar obrigações por cliente" />}
              </ListItem>
            </List>
             {/* Submenu Ações em Lote */}
          <ListItem button sx={{ pl: 4 }} onClick={handleAcoesEmLoteClick}>
                <ListItemIcon><SettingsIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Ações em Lote" />}
                {isOpen && (isAcoesEmLoteOpen ? <ExpandLess sx={{ color: '#ecf0f1' }} /> : <ExpandMore sx={{ color: '#ecf0f1' }} />)}
              </ListItem>
              <Collapse in={isAcoesEmLoteOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button sx={{ pl: 8 }} onClick={() => navigate('/alterar-departamentos')}>
                    <ListItemIcon><GroupWorkIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                    {isOpen && <ListItemText primary="Alterar departamentos" />}
                  </ListItem>
                  <ListItem button sx={{ pl: 8 }} onClick={() => navigate('/alterar-responsavel')}>
                    <ListItemIcon><StarBorderIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                    {isOpen && <ListItemText primary="Alterar responsável" />}
                  </ListItem>
                  <ListItem button sx={{ pl: 8 }} onClick={() => navigate('/prorrogar-tarefas')}>
                    <ListItemIcon><AccessTimeIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                    {isOpen && <ListItemText primary="Prorrogar tarefas" />}
                  </ListItem>
                  <ListItem button sx={{ pl: 8 }} onClick={() => navigate('/dispensar-tarefas')}>
                    <ListItemIcon><DoneAllIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                    {isOpen && <ListItemText primary="Dispensar tarefas" />}
                  </ListItem>
                  <ListItem button sx={{ pl: 8 }} onClick={() => navigate('/excluir-tarefas')}>
                    <ListItemIcon><DeleteIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                    {isOpen && <ListItemText primary="Excluir tarefas" />}
                  </ListItem>
                </List>
              </Collapse>
          </Collapse>

          {/* Configurações com Submenu */}
          <ListItem button onClick={handleConfigClick}>
            <ListItemIcon><SettingsIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
            {isOpen && <ListItemText primary="Configurações" />}
            {isOpen && (isConfigOpen ? <ExpandLess sx={{ color: '#ecf0f1' }} /> : <ExpandMore sx={{ color: '#ecf0f1' }} />)}
          </ListItem>
          <Collapse in={isConfigOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/grupo-cliente')}>
                <ListItemIcon><GroupIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Grupo de cliente" />}
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/status-cliente')}>
                <ListItemIcon><StarBorderIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Status do Cliente" />}
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/status-complementar')}>
                <ListItemIcon><StarBorderIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Status complementar" />}
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/escritorio')}>
                <ListItemIcon><BusinessIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Escritório" />}
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/categoria-cliente')}>
                <ListItemIcon><GroupIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Categoria do cliente" />}
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/segmento-empresa')}>
                <ListItemIcon><BusinessIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Segmento da empresa" />}
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/tipo-cliente')}>
                <ListItemIcon><GroupIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Tipo do cliente" />}
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/tags-cliente')}>
                <ListItemIcon><GroupIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Tags do cliente" />}
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/departamento')}>
                <ListItemIcon><GroupWorkIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Departamento" />}
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/responsavel-departamentos')}>
                <ListItemIcon><StarBorderIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Responsável padrão" />}
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/tipo-servico')}>
                <ListItemIcon><WorkIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Tipo de serviço" />}
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/grupo-servico')}>
                <ListItemIcon><WorkIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Grupo de serviço" />}
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/UsuariosPage')}>
                <ListItemIcon><GroupIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Usuários e permissões" />}
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/horario-acesso')}>
                <ListItemIcon><AccessTimeIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Horário de acesso" />}
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/calendario-feriados')}>
                <ListItemIcon><CalendarIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Calendário de feriados" />}
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/templates-email')}>
                <ListItemIcon><EmailIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Templates de email" />}
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/templates-whatsapp')}>
                <ListItemIcon><WhatsAppIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Templates de WhatsApp" />}
              </ListItem>
              
              
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/comportamento-entregas')}>
                <ListItemIcon><DoneAllIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Comportamento nas entregas" />}
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/cadastro-escritorio')}>
                <ListItemIcon><BusinessIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
                {isOpen && <ListItemText primary="Cadastro do escritório" />}
              </ListItem>

              
            </List>
          </Collapse>
        </List>
        <Divider sx={{ borderColor: '#34495e' }} />
        <List>
          <ListItem button onClick={handleOpenModal}>
            <ListItemIcon><StarIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
            {isOpen && <ListItemText primary="Avalie-nos" />}
          </ListItem>
          <ListItem button onClick={() => navigate('/logout')}>
            <ListItemIcon><ExitToAppIcon sx={{ color: '#ecf0f1' }} /></ListItemIcon>
            {isOpen && <ListItemText primary="Sair" />}
          </ListItem>
        </List>
      </Drawer>

      {/* Modal de Avaliação */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,  // Largura da modal
          maxWidth: '90%',  // Garante que a modal se ajuste em telas menores
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          borderRadius: '8px',
          textAlign: 'center',
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Sua avaliação é muito importante para nós!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Numa escala de 1 a 10, o quanto você recomendaria nossas soluções para um amigo ou colega?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
            {[...Array(10)].map((_, i) => (
              <IconButton key={i} onClick={() => handleRatingChange(i + 1)}>
                <span role="img" aria-label={`rating-${i + 1}`} style={{ fontSize: '24px' }}>
                  {getEmoji(i + 1)} {/* Função para obter o emoji correspondente */}
                </span>
                <Typography variant="caption" sx={{ color: i + 1 === rating ? 'blue' : 'inherit' }}>
                  {i + 1}
                </Typography>  {/* Número abaixo do emoji */}
              </IconButton>
            ))}
          </Box>
          {showFeedbackField && (
            <TextField
              label={feedbackPlaceholder}
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={feedback}
              onChange={handleFeedbackChange}
              sx={{ mb: 2 }}
            />
          )}
          <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mb: 1 }}>
            Avaliar
          </Button>
          <Button variant="text" color="secondary" onClick={handleCloseModal}>
            Fechar
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default Sidebar;
