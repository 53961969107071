import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider, CssBaseline, Box } from '@mui/material';
import GlobalStyles from './GlobalStyles';
import theme from './theme';
import Login from './components/Login/Login.js';
import DashboardPage from './components/DashboardPage';
import Sidebar from './components/Sidebar.js';
import Header from './components/Header/Header.js';
import ObrigacoesPage from './components/ObrigacoesPage';
import EmpresasPage from './components/Clientes/Clientes.js';
import NovaEmpresa from './components/NovaEmpresa'; 
import NovaObrigacao from './components/NovaObrigacao'; 
import OrdemServico from './components/OrdemServico'; 
import NovaOS from './components/nova-os'; 
import Controles from './components/Controles'; 
import Faq from './components/Faq'; 
import CRMContabilidade from './components/crm'; 
import DocumentManager from './components/arquivo'; 
import SupervisaoDashboard from './components/SupervisaoDashboard'; 
import Leads from './components/leads'; 
import ContasReceberPage from './components/ContasReceberPage'; 
import ContasPagarPage from './components/ContasPagarPage'; 
import FluxoCaixaPage from './components/FluxoCaixaPage'; 
import WorkflowPage from './components/WorkflowPage.js'; 
import Treinamento from './components/Treinamento.js';
import Messenger from './components/Messenger';
import UsuariosPage from './components/Configuracoes/Usuarios/UsuariosPage.js';

function AppContent() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const location = useLocation();

    const handleToggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    // Verifica se a página atual é a página de login
    const isLoginPage = location.pathname === '/login' || location.pathname === '/';

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            {!isLoginPage && (
                <>
                    <Header onToggleSidebar={handleToggleSidebar} isSidebarOpen={isSidebarOpen} />
                    <Sidebar isOpen={isSidebarOpen} />
                </>
            )}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    marginLeft: !isLoginPage && isSidebarOpen ? '240px' : '0px', // Ajuste da margem com base no estado do Sidebar
                    transition: 'margin 0.3s ease', // Suaviza a transição do Sidebar
                    padding: !isLoginPage ? '20px' : '0px', // Adiciona padding se não for a página de login
                }}
            >
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/dashboard" element={<DashboardPage />} />
                    <Route path="/obrigacoes" element={<ObrigacoesPage />} />
                    <Route path="/empresas" element={<EmpresasPage />} />
                    <Route path="/nova-empresa" element={<NovaEmpresa />} />
                    <Route path="/nova-obrigacao" element={<NovaObrigacao />} />
                    <Route path="/OrdemServico" element={<OrdemServico />} />
                    <Route path="/nova-os" element={<NovaOS />} />
                    <Route path="/controles" element={<Controles />} />
                    <Route path="/Faq" element={<Faq />} />
                    <Route path="/CRMContabilidade" element={<CRMContabilidade />} />
                    <Route path="/DocumentManager" element={<DocumentManager />} />
                    <Route path="/SupervisaoDashboard" element={<SupervisaoDashboard />} />
                    <Route path="/Leads" element={<Leads />} />
                    <Route path="/ContasReceberPage" element={<ContasReceberPage />} />
                    <Route path="/Messenger" element={<Messenger />} />
                    <Route path="/ContasPagarPage" element={<ContasPagarPage />} />
                    <Route path="/FluxoCaixaPage" element={<FluxoCaixaPage />} />
                    <Route path="/WorkflowPage" element={<WorkflowPage />} />
                    <Route path="/Treinamento" element={<Treinamento />} />
                    <Route path="/UsuariosPage" element={<UsuariosPage />} />
                    <Route path="/Login" element={<Login />} />
                </Routes>
            </Box>
        </Box>
    );
}

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyles />
            <Router>
                <AppContent />
            </Router>
        </ThemeProvider>
    );
}

export default App;
