import React, { useState } from 'react';
import {
  Box, Button, Container, Grid, TextField, Select, MenuItem, InputLabel, FormControl, Typography, Card, CardContent
} from '@mui/material';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';


const OrdemDeServico = () => {
  const [departamento, setDepartamento] = useState('');
  const [responsavel, setResponsavel] = useState('');
  const navigate = useNavigate();

  const handleDepartamentoChange = (event) => {
    const value = event.target.value;
    setDepartamento(value);
    setResponsavel(''); // Limpar responsável quando mudar o departamento
  };

  const handleSave = () => {
    // Lógica para salvar a ordem de serviço
  };

  const handleNew = () => {
    // Lógica para criar uma nova ordem de serviço
  };

  const handleBack = () => {
    navigate(-1); // Volta para a página anterior
  };

  // Responsáveis com base no departamento selecionado
  const getResponsaveis = () => {
    switch (departamento) {
      case 'Fiscal':
        return ['Janes', 'Lucirlene', 'Diego', 'Matheus', 'Aline', 'Raquel'];
      case 'RH':
        return ['Edmara', 'Amanda', 'Giovana', 'Lorraine', 'Yasmin'];
      case 'Sucesso do Cliente':
        return ['Elis', 'Mariusa'];
      case 'Contábil':
        return ['Marcos', 'Adilson'];
      default:
        return [];
    }
  };

  return (
    <Box sx={{ display: 'flex', bgcolor: '#f5f5f5', minHeight: '100vh' }}>
      <Sidebar />
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Container sx={{ mt: 4, p: 0 }}>
          <Typography variant="h4" gutterBottom sx={{ color: '#333', fontWeight: 'bold', mb: 4 }}>
            Cadastro de Ordem de Serviço
          </Typography>

          <Card sx={{ mt: 3 }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField fullWidth label="Código da O.S." variant="outlined" />
                </Grid>
              
                <Grid item xs={12} sm={6} md={3}>
                                        <InputMask
                                            mask="99.99.9999"
                                            alwaysShowMask={false}
                                            maskChar=""
                                        >
                                            {() => (
                                                <TextField
                                                    fullWidth
                                                    label="Classificação da O.S."
                                                    variant="outlined"
                                                    placeholder="00.00.0000"
                                                />
                                            )}
                                        </InputMask>
                                    </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <TextField fullWidth label="Nome da O.S." variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField fullWidth label="Mini Nome da O.S." variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Departamento</InputLabel>
                    <Select label="Departamento" value={departamento} onChange={handleDepartamentoChange}>
                      <MenuItem value="Fiscal">Fiscal</MenuItem>
                      <MenuItem value="Contábil">Contábil</MenuItem>
                      <MenuItem value="RH">RH</MenuItem>
                      <MenuItem value="Sucesso do Cliente">Sucesso do Cliente</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined" disabled={!departamento}>
                    <InputLabel>Responsável</InputLabel>
                    <Select
                      label="Responsável"
                      value={responsavel}
                      onChange={(e) => setResponsavel(e.target.value)}
                    >
                      {getResponsaveis().map((name) => (
                        <MenuItem key={name} value={name}>{name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Tipo do Serviço</InputLabel>
                                            <Select label="Selecione">
                                                <MenuItem value="Exemplo 1">Exemplo 1</MenuItem>
                                                <MenuItem value="Exemplo 1">Exemplo 1</MenuItem>
                                               
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Grupo do Serviço</InputLabel>
                                            <Select label="Selecione">
                                                <MenuItem value="Exemplo 1">Exemplo 1</MenuItem>
                                                <MenuItem value="Exemplo 1">Exemplo 1</MenuItem>
                                         
                                            </Select>
                                        </FormControl>
                                    </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    label="Quantidade de dias para conclusão da O.S."
                    variant="outlined"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    label="Quantidade de dias para entregar O.S ao cliente"
                    variant="outlined"
                    type="number"
                    value="quant_dias_os"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    label="Quantidade de dias para iniciar a O.S."
                    variant="outlined"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Prazos fixos em dias não úteis</InputLabel>
                    <Select label="Prazos fixos em dias não úteis">
                      <MenuItem value="Antecipar para o dia útil anterior">Antecipar para o dia útil anterior</MenuItem>
                      <MenuItem value="Postergar para o próximo dia útil">Postergar para o próximo dia útil</MenuItem>
                      <MenuItem value="Manter o dia exato">Manter o dia exato</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Sábado é útil?</InputLabel>
                    <Select label="Sábado é útil?">
                      <MenuItem value="N">Não</MenuItem>
                      <MenuItem value="S">Sim</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Tipos dos dias antes</InputLabel>
                    <Select label="Tipos dos dias antes">
                      <MenuItem value="Dias úteis">Dias úteis</MenuItem>
                      <MenuItem value="Dias corridos">Dias corridos</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Pode ser finalizado/concluído antes dos passos vinculados?</InputLabel>
                    <Select label="Pode ser finalizado/concluído antes dos passos vinculados?">
                      <MenuItem value="S">Sim</MenuItem>
                      <MenuItem value="N">Não</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Observação"
                    variant="outlined"
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item>
              <Button variant="contained" color="success" onClick={handleSave}>
                Salvar
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleNew}>
                Nova O.S.
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="warning" onClick={handleBack}>
                Voltar
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default OrdemDeServico;
