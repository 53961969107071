import React, { useState } from 'react';
import { Avatar, Menu, MenuItem, IconButton, Toolbar, AppBar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FaBell, FaCog, FaBars } from 'react-icons/fa';

const Header = ({ onToggleSidebar }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  // Função para abrir o menu quando clicar no Avatar
  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Função para fechar o menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Função para fazer logout
  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove o token ou qualquer dado salvo
    handleMenuClose(); // Fecha o menu
    navigate('/login'); // Redireciona para a página de login
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1, // Coloca o AppBar acima do Sidebar
        backgroundColor: '#2c3e50', // Cor de fundo do AppBar
        color: '#ecf0f1', // Cor do texto do AppBar
        boxShadow: 'none', // Remove a sombra
        height: '50px', // Altura do AppBar ajustada
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between', minHeight: '30px' }}>
        {/* Ícone para abrir/fechar Sidebar */}
        <IconButton color="inherit" onClick={onToggleSidebar} sx={{ padding: '4px' }}>
          <FaBars size={20} /> {/* Ícone estilizado menor */}
        </IconButton>

        {/* Ícones à direita do AppBar */}
        <div>
          <IconButton color="inherit" sx={{ padding: '4px' }}>
            <FaBell size={20} /> {/* Ícone de sino estilizado */}
          </IconButton>
          <IconButton color="inherit" sx={{ padding: '4px' }}>
            <FaCog size={20} /> {/* Ícone de configurações estilizado */}
          </IconButton>
          <IconButton color="inherit" onClick={handleAvatarClick} sx={{ padding: '4px' }}>
            <Avatar sx={{ width: 24, height: 24 }}>U</Avatar> {/* Avatar menor */}
          </IconButton>
        </div>

        {/* Menu que aparece ao clicar no Avatar */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => { navigate('/meu-perfil'); handleMenuClose(); }}>
            Meu Perfil
          </MenuItem>
          <MenuItem onClick={handleLogout}>Sair</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
