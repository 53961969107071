import React, { useState, useMemo } from 'react';
import Sidebar from '../../Sidebar';
import {
  Box, Button, Typography, Stack, IconButton, Tooltip, Container, Modal, Avatar, TextField, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import './UsuariosPage.css'; // Estilos personalizados

const initialRows = [
  { id: 1, nome: 'Usuário 1', email: 'usuario1@empresa.com', tipoUsuario: 'Administrador', avatar: 'U1' },
  { id: 2, nome: 'Usuário 2', email: 'usuario2@empresa.com', tipoUsuario: 'Gestor', avatar: 'U2' },
  // Adicione mais dados de exemplo aqui
];

const UsuariosPage = () => {
  const [rows, setRows] = useState(initialRows);
  const [searchText, setSearchText] = useState('');
  const [openModal, setOpenModal] = useState(false); // Controle do modal de adicionar
  const [modalType, setModalType] = useState(''); // Controle de tipo de modal: 'visualizar', 'editar', 'remover'
  const [selectedUser, setSelectedUser] = useState(null); // Usuário selecionado para editar/visualizar/remover

  const [newUser, setNewUser] = useState({
    nome: '',
    email: '',
    senha: '',
    tipoUsuario: '',
    telefone: '',
  });

  const navigate = useNavigate();

  // Abrir modal de novo usuário
  const handleOpenModal = () => {
    setNewUser({ nome: '', email: '', senha: '', tipoUsuario: '', telefone: '' });
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Função para abrir modal de visualizar, editar ou remover
  const handleOpenActionModal = (type, user) => {
    setModalType(type);
    setSelectedUser(user);
  };

  const handleSave = () => {
    setRows([...rows, { ...newUser, id: rows.length + 1 }]);
    handleCloseModal();
  };

  const handleEdit = () => {
    const updatedRows = rows.map(row =>
      row.id === selectedUser.id ? { ...selectedUser } : row
    );
    setRows(updatedRows);
    setModalType('');
    setSelectedUser(null);
  };

  const handleDelete = () => {
    const updatedRows = rows.filter(row => row.id !== selectedUser.id);
    setRows(updatedRows);
    setModalType('');
    setSelectedUser(null);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'avatar',
        header: '',
        Cell: ({ row }) => (
          <Avatar>{row.original.nome.charAt(0)}</Avatar> // Gera o avatar com a primeira letra do nome
        ),
        size: 200,
      },
      { accessorKey: 'nome', header: 'Nome do Usuário', size: 200 },
      { accessorKey: 'email', header: 'Email', size: 250 },
      { accessorKey: 'tipoUsuario', header: 'Perfil', size: 150 },
      {
        accessorKey: 'acoes',
        header: 'Ações',
        size: 175,
        Cell: ({ row }) => (
          <Stack direction="row" spacing={1}>
            <Tooltip title="Visualizar">
              <IconButton
                color="primary"
                onClick={() => handleOpenActionModal('visualizar', row.original)}
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Editar">
              <IconButton
                color="warning"
                onClick={() => handleOpenActionModal('editar', row.original)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remover">
              <IconButton
                color="error"
                onClick={() => handleOpenActionModal('remover', row.original)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
      },
    ],
    []
  );

  const filteredRows = useMemo(() => {
    return rows.filter((row) =>
      Object.keys(row).some((field) =>
        row[field].toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [rows, searchText]);

  return (
    <Box className="usuarios-page">
      <Sidebar />
      <Box className="main-content">
        <Container maxWidth="lg" className="container">
          <Box className="table-container" mt={2}>
            <MaterialReactTable
              columns={columns}
              data={filteredRows}
              initialState={{
                density: 'comfortable',
                showGlobalFilter: false, // Desativar filtro global por padrão
                showColumnFilters: false, // Desativar filtro de colunas por padrão
              }}
              muiTableContainerProps={{
                sx: { 
                  maxHeight: 'calc(100vh - 200px)',
                  backgroundColor: '#fff', 
                  borderRadius: 1
                },
              }}
              muiTableHeadCellProps={{
                sx: { 
                  backgroundColor: '#1976d2', 
                  color: '#fff', 
                  fontWeight: 'bold',
                  borderBottom: '2px solid #fff' 
                },
              }}
              muiTableBodyCellProps={{
                sx: { 
                  padding: '8px',
                  borderBottom: '1px solid #ddd', 
                  backgroundColor: '#fff'
                },
              }}
              enableRowSelection
              enableColumnDragging
              enableColumnResizing
              enableStickyHeader
              enableFullScreenToggle={true}
              renderTopToolbarCustomActions={() => (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleOpenModal}
                >
                  Novo Usuário
                </Button>
              )}
              localization={{
                searchPlaceholder: 'Pesquisar...',
                toolbarDensity: 'Densidade',
                toolbarFilters: 'Filtros',
                toolbarExportCSV: 'Exportar CSV',
                toolbarColumns: 'Colunas',
                toolbarActions: 'Ações',
                toolbarExportAllRows: 'Exportar todas as linhas',
                toolbarExportSelectedRows: 'Exportar linhas selecionadas',
                paginationDisplayRows: 'de',
                paginationRowsPerPage: 'Linhas por página',
              }}
              muiSearchTextFieldProps={{
                variant: 'outlined',
                placeholder: 'Pesquisar usuário...',
                value: searchText,
                onChange: (e) => setSearchText(e.target.value),
              }}
              muiTablePaginationProps={{
                rowsPerPageOptions: [5, 10, 20],
                labelRowsPerPage: 'Linhas por página:',
              }}
            />
          </Box>
        </Container>

        {/* Modal de Novo Usuário */}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal-content" sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            borderRadius: 1,
            boxShadow: 24,
            p: 4,
          }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Novo Usuário
            </Typography>
            <Stack spacing={2} mt={2}>
              <TextField
                label="Nome do Usuário"
                variant="outlined"
                name="nome"
                value={newUser.nome}
                onChange={(e) => setNewUser({ ...newUser, nome: e.target.value })}
                fullWidth
              />
              <TextField
                label="Email"
                variant="outlined"
                name="email"
                value={newUser.email}
                onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                fullWidth
              />
              <TextField
                label="Senha"
                type="password"
                variant="outlined"
                name="senha"
                value={newUser.senha}
                onChange={(e) => setNewUser({ ...newUser, senha: e.target.value })}
                fullWidth
              />
              <TextField
                label="Telefone"
                variant="outlined"
                name="telefone"
                value={newUser.telefone}
                onChange={(e) => setNewUser({ ...newUser, telefone: e.target.value })}
                fullWidth
              />
              <TextField
                select
                label="Tipo de Usuário"
                variant="outlined"
                name="tipoUsuario"
                value={newUser.tipoUsuario}
                onChange={(e) => setNewUser({ ...newUser, tipoUsuario: e.target.value })}
                fullWidth
              >
                <MenuItem value="Administrador">Administrador</MenuItem>
                <MenuItem value="Gestor">Gestor</MenuItem>
                <MenuItem value="Colaborador">Colaborador</MenuItem>
              </TextField>
              <Stack direction="row" spacing={2} mt={2}>
                <Button variant="contained" color="primary" onClick={handleSave}>
                  Salvar
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleCloseModal}>
                  Cancelar
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Modal>

        {/* Modais de Visualizar, Editar e Remover */}
        {selectedUser && modalType === 'visualizar' && (
          <Dialog open onClose={() => setModalType('')}>
            <DialogTitle>Visualizar Usuário</DialogTitle>
            <DialogContent>
              <Typography>Nome: {selectedUser.nome}</Typography>
              <Typography>Email: {selectedUser.email}</Typography>
              <Typography>Perfil: {selectedUser.tipoUsuario}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setModalType('')} color="primary">
                Fechar
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {selectedUser && modalType === 'editar' && (
          <Dialog open onClose={() => setModalType('')}>
            <DialogTitle>Editar Usuário</DialogTitle>
            <DialogContent>
              <TextField
                label="Nome do Usuário"
                value={selectedUser.nome}
                onChange={(e) => setSelectedUser({ ...selectedUser, nome: e.target.value })}
                fullWidth
              />
              <TextField
                label="Email"
                value={selectedUser.email}
                onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })}
                fullWidth
              />
              <TextField
                label="Perfil"
                value={selectedUser.tipoUsuario}
                onChange={(e) => setSelectedUser({ ...selectedUser, tipoUsuario: e.target.value })}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEdit} color="primary">
                Salvar
              </Button>
              <Button onClick={() => setModalType('')} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {selectedUser && modalType === 'remover' && (
          <Dialog open onClose={() => setModalType('')}>
            <DialogTitle>Remover Usuário</DialogTitle>
            <DialogContent>
              <Typography>Tem certeza que deseja remover {selectedUser.nome}?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDelete} color="error">
                Remover
              </Button>
              <Button onClick={() => setModalType('')} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>
        )}

      </Box>
    </Box>
  );
};

export default UsuariosPage;
