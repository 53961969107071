import React, { useMemo, useState } from 'react';
import Sidebar from '../Sidebar';
import {
  Box, Button, Typography, Stack, IconButton, Tooltip, Container, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, InputLabel, FormControl, Checkbox, ListItemText
} from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import './Clientes.css'; // Estilos personalizados

const initialRows = [
  { id: 1, nome: 'Empresa Paraná 1', cnpj: '01.234.567/0001-01', responsavel: 'João Silva', telefone: '(41) 99999-9999', email: 'empresa1@parana.com.br' },
  { id: 2, nome: 'Empresa Paraná 2', cnpj: '01.234.567/0001-02', responsavel: 'Maria Souza', telefone: '(41) 88888-8888', email: 'empresa2@parana.com.br' },
  { id: 3, nome: 'Empresa São Paulo 1', cnpj: '12.345.678/0001-01', responsavel: 'Carlos Santos', telefone: '(11) 99999-9999', email: 'empresa1@saopaulo.com.br' },
  { id: 4, nome: 'Empresa São Paulo 2', cnpj: '12.345.678/0001-02', responsavel: 'Ana Lima', telefone: '(11) 88888-8888', email: 'empresa2@saopaulo.com.br' },
  { id: 5, nome: 'Empresa Rio de Janeiro 1', cnpj: '23.456.789/0001-01', responsavel: 'Luiz Almeida', telefone: '(21) 99999-9999', email: 'empresa1@riodejaneiro.com.br' },
  { id: 6, nome: 'Empresa Rio de Janeiro 2', cnpj: '23.456.789/0001-02', responsavel: 'Cláudia Costa', telefone: '(21) 88888-8888', email: 'empresa2@riodejaneiro.com.br' },
  { id: 7, nome: 'Empresa Paraná 3', cnpj: '01.234.567/0001-03', responsavel: 'Roberto Silva', telefone: '(41) 77777-7777', email: 'empresa3@parana.com.br' },
  { id: 8, nome: 'Empresa Paraná 4', cnpj: '01.234.567/0001-04', responsavel: 'Paula Souza', telefone: '(41) 66666-6666', email: 'empresa4@parana.com.br' },
  { id: 9, nome: 'Empresa São Paulo 3', cnpj: '12.345.678/0001-03', responsavel: 'Felipe Andrade', telefone: '(11) 77777-7777', email: 'empresa3@saopaulo.com.br' },
  { id: 10, nome: 'Empresa São Paulo 4', cnpj: '12.345.678/0001-04', responsavel: 'Fernanda Lima', telefone: '(11) 66666-6666', email: 'empresa4@saopaulo.com.br' },
  { id: 11, nome: 'Empresa Rio de Janeiro 3', cnpj: '23.456.789/0001-03', responsavel: 'Rafael Vieira', telefone: '(21) 77777-7777', email: 'empresa3@riodejaneiro.com.br' },
  { id: 12, nome: 'Empresa Rio de Janeiro 4', cnpj: '23.456.789/0001-04', responsavel: 'Fábio Ramos', telefone: '(21) 66666-6666', email: 'empresa4@riodejaneiro.com.br' },
  { id: 13, nome: 'Empresa Paraná 5', cnpj: '01.234.567/0001-05', responsavel: 'André Ferreira', telefone: '(41) 55555-5555', email: 'empresa5@parana.com.br' },
  { id: 14, nome: 'Empresa São Paulo 5', cnpj: '12.345.678/0001-05', responsavel: 'Viviane Torres', telefone: '(11) 55555-5555', email: 'empresa5@saopaulo.com.br' },
  { id: 15, nome: 'Empresa Rio de Janeiro 5', cnpj: '23.456.789/0001-05', responsavel: 'Márcio Oliveira', telefone: '(21) 55555-5555', email: 'empresa5@riodejaneiro.com.br' },
  { id: 16, nome: 'Empresa Paraná 6', cnpj: '01.234.567/0001-06', responsavel: 'Sofia Cardoso', telefone: '(41) 44444-4444', email: 'empresa6@parana.com.br' },
  { id: 17, nome: 'Empresa São Paulo 6', cnpj: '12.345.678/0001-06', responsavel: 'Henrique Barbosa', telefone: '(11) 44444-4444', email: 'empresa6@saopaulo.com.br' },
  { id: 18, nome: 'Empresa Rio de Janeiro 6', cnpj: '23.456.789/0001-06', responsavel: 'Renata Almeida', telefone: '(21) 44444-4444', email: 'empresa6@riodejaneiro.com.br' },
  { id: 19, nome: 'Empresa Paraná 7', cnpj: '01.234.567/0001-07', responsavel: 'Paulo Souza', telefone: '(41) 33333-3333', email: 'empresa7@parana.com.br' },
  { id: 20, nome: 'Empresa São Paulo 7', cnpj: '12.345.678/0001-07', responsavel: 'Marina Duarte', telefone: '(11) 33333-3333', email: 'empresa7@saopaulo.com.br' },
];

const EmpresasPage = () => {
  const [rows, setRows] = useState(initialRows);
  const [searchText, setSearchText] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedNome, setSelectedNome] = useState([]);
  const [selectedCNPJ, setSelectedCNPJ] = useState([]);
  const [selectedResponsavel, setSelectedResponsavel] = useState([]);
  const [selectedTelefone, setSelectedTelefone] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState([]);
  const navigate = useNavigate();

  const handleChangeSelect = (setSelected, value) => {
    setSelected(value);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'nome',
        header: 'Nome da Empresa',
        size: 200,
        filterFn: 'equals',
        Filter: ({ column }) => (
          <FormControl fullWidth variant="standard">
            <InputLabel>Nome da Empresa</InputLabel>
            <Select
              multiple
              value={selectedNome}
              onChange={(e) => handleChangeSelect(setSelectedNome, e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {Array.from(new Set(initialRows.map(row => row.nome))).map(nome => (
                <MenuItem key={nome} value={nome}>
                  <Checkbox checked={selectedNome.indexOf(nome) > -1} />
                  <ListItemText primary={nome} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      },
      {
        accessorKey: 'cnpj',
        header: 'CNPJ',
        size: 150,
        filterFn: 'equals',
        Filter: ({ column }) => (
          <FormControl fullWidth variant="standard">
            <InputLabel>CNPJ</InputLabel>
            <Select
              multiple
              value={selectedCNPJ}
              onChange={(e) => handleChangeSelect(setSelectedCNPJ, e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {Array.from(new Set(initialRows.map(row => row.cnpj))).map(cnpj => (
                <MenuItem key={cnpj} value={cnpj}>
                  <Checkbox checked={selectedCNPJ.indexOf(cnpj) > -1} />
                  <ListItemText primary={cnpj} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      },
      {
        accessorKey: 'responsavel',
        header: 'Responsável',
        size: 200,
        filterFn: 'equals',
        Filter: ({ column }) => (
          <FormControl fullWidth variant="standard">
            <InputLabel>Responsável</InputLabel>
            <Select
              multiple
              value={selectedResponsavel}
              onChange={(e) => handleChangeSelect(setSelectedResponsavel, e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {Array.from(new Set(initialRows.map(row => row.responsavel))).map(responsavel => (
                <MenuItem key={responsavel} value={responsavel}>
                  <Checkbox checked={selectedResponsavel.indexOf(responsavel) > -1} />
                  <ListItemText primary={responsavel} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      },
      {
        accessorKey: 'telefone',
        header: 'Telefone',
        size: 150,
        filterFn: 'equals',
        Filter: ({ column }) => (
          <FormControl fullWidth variant="standard">
            <InputLabel>Telefone</InputLabel>
            <Select
              multiple
              value={selectedTelefone}
              onChange={(e) => handleChangeSelect(setSelectedTelefone, e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {Array.from(new Set(initialRows.map(row => row.telefone))).map(telefone => (
                <MenuItem key={telefone} value={telefone}>
                  <Checkbox checked={selectedTelefone.indexOf(telefone) > -1} />
                  <ListItemText primary={telefone} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 250,
        filterFn: 'equals',
        Filter: ({ column }) => (
          <FormControl fullWidth variant="standard">
            <InputLabel>Email</InputLabel>
            <Select
              multiple
              value={selectedEmail}
              onChange={(e) => handleChangeSelect(setSelectedEmail, e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {Array.from(new Set(initialRows.map(row => row.email))).map(email => (
                <MenuItem key={email} value={email}>
                  <Checkbox checked={selectedEmail.indexOf(email) > -1} />
                  <ListItemText primary={email} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      },
      {
        accessorKey: 'acoes',
        header: 'Ações',
        size: 150,
        Cell: ({ row }) => (
          <Stack direction="row" spacing={1}>
            <Tooltip title="Visualizar">
              <IconButton
                color="primary"
                onClick={() => handleOpenModal(row.original, 'visualizar')}
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Editar">
              <IconButton
                color="warning"
                onClick={() => handleOpenModal(row.original, 'editar')}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remover">
              <IconButton
                color="error"
                onClick={() => handleOpenModal(row.original, 'remover')}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
      },
    ],
    [selectedNome, selectedCNPJ, selectedResponsavel, selectedTelefone, selectedEmail]
  );

  const handleOpenModal = (row, action) => {
    setSelectedRow({ ...row, action });
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedRow(null);
    setOpenModal(false);
  };

  const filteredRows = useMemo(() => {
    return rows.filter((row) => {
      const filterByNome = selectedNome.length === 0 || selectedNome.includes(row.nome);
      const filterByCNPJ = selectedCNPJ.length === 0 || selectedCNPJ.includes(row.cnpj);
      const filterByResponsavel = selectedResponsavel.length === 0 || selectedResponsavel.includes(row.responsavel);
      const filterByTelefone = selectedTelefone.length === 0 || selectedTelefone.includes(row.telefone);
      const filterByEmail = selectedEmail.length === 0 || selectedEmail.includes(row.email);

      return filterByNome && filterByCNPJ && filterByResponsavel && filterByTelefone && filterByEmail;
    });
  }, [rows, selectedNome, selectedCNPJ, selectedResponsavel, selectedTelefone, selectedEmail]);

  return (
    <Box className="empresas-page" sx={{ height: '100vh', display: 'flex' }}>
      <Sidebar />
      <Box className="main-content" sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <Container maxWidth="lg" className="container" sx={{ height: '100%', padding: '0', overflow: 'hidden' }}>
          <Box className="table-container" mt={2} sx={{ height: '100%' }}>
            <MaterialReactTable
              columns={columns}
              data={filteredRows}
              initialState={{
                density: 'comfortable',
                showGlobalFilter: false,
                showColumnFilters: true, // Habilitando o filtro por coluna
              }}
              muiTableContainerProps={{
                sx: { 
                  maxHeight: 'calc(100vh - 120px)',
                  backgroundColor: '#fff',
                  borderRadius: 1,
                  overflowY: 'auto',
                },
              }}
              muiTableHeadCellProps={{
                sx: { 
                  backgroundColor: '#1976d2',
                  color: '#fff',
                  fontWeight: 'bold',
                  borderBottom: '2px solid #ff9800'
                },
              }}
              muiTableBodyCellProps={{
                sx: { 
                  padding: '8px',
                  borderBottom: '1px solid #ddd',
                  backgroundColor: '#fff'
                },
              }}
              enableRowSelection
              enableColumnDragging
              enableColumnResizing
              enableStickyHeader
              enableFullScreenToggle={true}
              renderTopToolbarCustomActions={() => (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => navigate('/nova-empresa')}
                >
                  Novo Cliente
                </Button>
              )}
              localization={{
                actions: 'Ações',
                searchPlaceholder: 'Pesquisar...',
                toolbarDensity: 'Densidade',
                toolbarFilters: 'Filtros',
                toolbarExportCSV: 'Exportar CSV',
                toolbarColumns: 'Colunas',
                toolbarExportAllRows: 'Exportar todas as linhas',
                toolbarExportSelectedRows: 'Exportar linhas selecionadas',
                paginationDisplayRows: 'de',
                paginationRowsPerPage: 'Linhas por página',
                showHideColumns: 'Mostrar/Esconder Colunas',
                filterByColumn: 'Filtrar por coluna',
              }}
              muiSearchTextFieldProps={{
                variant: 'outlined',
                placeholder: 'Pesquisar cliente...',
                value: searchText,
                onChange: (e) => setSearchText(e.target.value),
              }}
              muiTablePaginationProps={{
                rowsPerPageOptions: [5, 10, 20],
                labelRowsPerPage: 'Linhas por página:',
              }}
            />
          </Box>
        </Container>
      </Box>

      {/* Modal para Visualizar, Editar ou Remover */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>{selectedRow?.action === 'visualizar' ? 'Visualizar' : selectedRow?.action === 'editar' ? 'Editar' : 'Remover'} Empresa</DialogTitle>
        <DialogContent>
          <Typography>Nome: {selectedRow?.nome}</Typography>
          <Typography>CNPJ: {selectedRow?.cnpj}</Typography>
          <Typography>Responsável: {selectedRow?.responsavel}</Typography>
          <Typography>Telefone: {selectedRow?.telefone}</Typography>
          <Typography>Email: {selectedRow?.email}</Typography>
        </DialogContent>
        <DialogActions>
          {selectedRow?.action === 'remover' ? (
            <Button color="error" onClick={() => alert('Empresa removida.')}>
              Remover
            </Button>
          ) : selectedRow?.action === 'editar' ? (
            <Button color="primary" onClick={() => alert('Empresa editada.')}>
              Salvar
            </Button>
          ) : null}
          <Button onClick={handleCloseModal} color="secondary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EmpresasPage;
