import React, { useState } from 'react';
import { Container, Box, Grid, Card, CardContent, Typography, TextField, InputAdornment, Button, MenuItem, Modal, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VerifiedIcon from '@mui/icons-material/Verified'; // Usado para categorias que não foram alteradas
import SettingsIcon from '@mui/icons-material/Settings'; // Usado para categorias que não foram alteradas
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import GavelIcon from '@mui/icons-material/Gavel'; // Importação do ícone de governo

import logo from './bg_logo_branca.png';

const categories = [
  { title: "Sefaz", icon: <img src="https://athixdev.xyz/conttalk/sefaz.png" alt="Sefaz" style={{ width: 60, height: 60 }} /> },
  { title: "Receita Federal", icon: <img src="https://athixdev.xyz/conttalk/receita.png" alt="Receita Federal" style={{ width: 60, height: 60 }} /> },
  { title: "Prefeitura", icon: <img src="https://athixdev.xyz/conttalk/prefeitura.png" alt="Prefeitura" style={{ width: 60, height: 60 }} /> },
  { title: "INSS", icon: <img src="https://athixdev.xyz/conttalk/inss.png" alt="INSS" style={{ width: 60, height: 60 }} /> },
  { title: "PGFN", icon: <img src="https://athixdev.xyz/conttalk/pgfn.png" alt="PGFN" style={{ width: 60, height: 60 }} /> },
  { title: "Outros órgãos", icon: <GavelIcon sx={{ fontSize: 60, color: '#FF6600' }} /> },
  { title: "Interno", icon: <VerifiedIcon sx={{ fontSize: 60, color: '#FF6600' }} /> },
  { title: "Configurações", icon: <SettingsIcon sx={{ fontSize: 60, color: '#FF6600' }} /> },
];

export default function KnowledgeBase() {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const currentDateTime = new Date().toLocaleString();

  const handleBackClick = () => {
    navigate(-1); // Volta para a página anterior
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <Box sx={{ backgroundColor: '#2c3e50', color: '#fff', padding: '40px 0', textAlign: 'center', width: '100%' }}>
        <img
          src={logo}  // Usando o logo importado aqui
          alt="Logo"
          style={{ width: '150px', marginBottom: '20px' }}
        />
        <Typography variant="h4" gutterBottom>
          Base de conhecimento
        </Typography>
      </Box>

      <Container maxWidth="lg">
        <Box sx={{ marginTop: 4, backgroundColor: '#fff', borderRadius: 1, padding: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                placeholder="Procurar por assunto..."
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                select
                label="Cliente"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="cliente1">Cliente 1</MenuItem>
                <MenuItem value="cliente2">Cliente 2</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                select
                label="Departamento"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="departamento1">Departamento 1</MenuItem>
                <MenuItem value="departamento2">Departamento 2</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                select
                label="Órgão"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="orgao1">Órgão 1</MenuItem>
                <MenuItem value="orgao2">Órgão 2</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                select
                label="Usuário"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="usuario1">Usuário 1</MenuItem>
                <MenuItem value="usuario2">Usuário 2</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                label="Data Inicial"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                label="Data Final"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                fullWidth
                sx={{ backgroundColor: '#FF6600', '&:hover': { backgroundColor: '#E65100' } }}
              >
                Confirmar Pesquisa
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="outlined"
                fullWidth
                sx={{ borderColor: '#FF6600', color: '#FF6600', '&:hover': { borderColor: '#E65100', color: '#E65100' } }}
              >
                Limpar
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ marginTop: 4 }}>
          <Grid container spacing={4}>
            {categories.map((category) => (
              <Grid item xs={12} sm={6} md={3} key={category.title}>
                <Card sx={{ height: '100%', border: '1px solid #FF6600', borderRadius: '8px', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)' } }}>
                  <CardContent sx={{ textAlign: 'center' }}>
                    {category.icon}
                    <Typography variant="h6" sx={{ marginTop: 2, fontWeight: 'bold' }}>
                      {category.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>

      <Button
        variant="contained"
        startIcon={<ArrowBackIcon />}
        sx={{
          position: 'fixed',
          bottom: 16,
          left: 16,
          backgroundColor: '#FF6600',
          '&:hover': { backgroundColor: '#E65100' },
          zIndex: 1000,
        }}
        onClick={handleBackClick}
      >
        Voltar
      </Button>

      <Button
        variant="contained"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          backgroundColor: '#FF6600',
          '&:hover': { backgroundColor: '#E65100' },
          zIndex: 1000,
        }}
        onClick={handleOpenModal}
      >
        Incluir Solicitação de Suporte
      </Button>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          border: '2px solid #FF6600',
          boxShadow: 24,
          p: 4,
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
            <Typography variant="h6" component="h2">
              Nova Solicitação de Suporte
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Cliente"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="cliente1">Cliente 1</MenuItem>
                <MenuItem value="cliente2">Cliente 2</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Departamento"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="departamento1">Departamento 1</MenuItem>
                <MenuItem value="departamento2">Departamento 2</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Órgão"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="orgao1">Órgão 1</MenuItem>
                <MenuItem value="orgao2">Órgão 2</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Usuário"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="usuario1">Usuário 1</MenuItem>
                <MenuItem value="usuario2">Usuário 2</MenuItem>
              </TextField>
            </Grid>
           
           
            <Grid item xs={12}>
              <TextField
                label="Data e Hora"
                value={currentDateTime}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Assunto"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Descrição"
                multiline
                rows={4}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                component="label"
                fullWidth
                startIcon={<AttachFileIcon />}
              >
                Anexar Arquivo
                <input type="file" hidden />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{ backgroundColor: '#FF6600', '&:hover': { backgroundColor: '#E65100' } }}
                fullWidth
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
