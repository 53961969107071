import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#1976d2', // Azul
        },
        secondary: {
            main: '#dc004e', // Vermelho
        },
        background: {
            default: '#f5f5f5', // Cor de fundo padrão
        },
        text: {
            primary: '#333333', // Cor principal do texto
            secondary: '#757575', // Cor secundária do texto
        },
    },
    typography: {
        fontFamily: 'Poppins, Arial, sans-serif',
        h1: {
            fontSize: '2.5rem',
            fontWeight: 500,
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 500,
        },
        h3: {
            fontSize: '1.75rem',
            fontWeight: 500,
        },
        button: {
            textTransform: 'none',
            fontWeight: 600,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 8, // Borda arredondada para os botões
                    padding: '8px 16px',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1976d2', // Azul
                },
            },
        },
    },
});

export default theme;
