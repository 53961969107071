import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  .main-content {
    margin-top: 64px; 
    margin-left: 240px; 
    padding: 20px;
    background-color: #ffffff; /* Fundo branco para o conteúdo principal */
  }

  .chart-container {
    position: relative;
    height: 300px; 
  }

  .icon-card {
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .supervisao-dashboard-card {
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    margin-top: 20px;
  }

  /* Estilos adicionais conforme necessário */
`;

export default GlobalStyles;
