import React, { useMemo, useState } from 'react';
import { Box, Button, Typography, Container, TextField } from '@mui/material'; // Adicionei TextField aqui
import { useNavigate } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import SearchIcon from '@mui/icons-material/Search';
import Sidebar from './Sidebar'; // Importe o Sidebar se necessário

const initialData = [
  { id: 1, nome: 'Controle de Acesso', tipo: 'Segurança', status: 'Ativo' },
  { id: 2, nome: 'Controle Financeiro', tipo: 'Financeiro', status: 'Pendente' },
  { id: 3, nome: 'Controle de Estoque', tipo: 'Logística', status: 'Ativo' },
  { id: 4, nome: 'Controle de Qualidade', tipo: 'Qualidade', status: 'Ativo' },
];

const ControlesPage = () => {
  const [data, setData] = useState(initialData);
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      { accessorKey: 'nome', header: 'Nome do Controle', size: 200, filterFn: 'fuzzy' },
      { accessorKey: 'tipo', header: 'Tipo', size: 150, filterFn: 'fuzzy' },
      { accessorKey: 'status', header: 'Status', size: 150 },
    ],
    []
  );

  const filteredData = useMemo(() => {
    return data.filter((row) =>
      Object.keys(row).some((field) =>
        row[field].toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [data, searchText]);

  const handleNewControl = () => {
    navigate('/NovoControle'); // Redireciona para a página de criação de novo controle
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Sidebar />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Container sx={{ flexGrow: 1, mt: 2 }}>
          <Typography variant="h4" gutterBottom>
            Controles
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <TextField
              variant="outlined"
              placeholder="Pesquisar controle"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              InputProps={{
                startAdornment: (
                  <SearchIcon />
                ),
              }}
            />
            <Box>
              <Button
                variant="contained"
                color="primary"
                sx={{ ml: 2 }}
                onClick={handleNewControl}
              >
                Novo Controle
              </Button>
            </Box>
          </Box>
          <Box sx={{ height: '100%', width: '100%' }}>
            <MaterialReactTable
              columns={columns}
              data={filteredData}
              initialState={{ density: 'comfortable' }}
              muiTableContainerProps={{ sx: { maxHeight: 'calc(100vh - 200px)' } }}
              muiTableBodyCellProps={{ sx: { p: '8px' } }}
              enableRowSelection
              enableColumnDragging
              enableColumnResizing
              enableStickyHeader
              enableFullScreenToggle={false}
              localization={{
                searchPlaceholder: 'Pesquisar...',
              }}
              muiSearchTextFieldProps={{
                variant: 'outlined',
                placeholder: 'Pesquisar controle...',
                value: searchText,
                onChange: (e) => setSearchText(e.target.value),
                InputProps: {
                  startAdornment: (
                    <SearchIcon />
                  ),
                },
              }}
            />
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default ControlesPage;
