import React, { useState } from 'react';
import './LoginPage.css';
import { useNavigate } from 'react-router-dom';
import logo from './bg_logo.png'; // Importando a imagem

const LoginPage = () => {
  const [email, setEmail] = useState(''); // Removido o valor padrão
  const [senha, setSenha] = useState(''); // Removido o valor padrão
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email || !senha) {
      setError('Informe seu e-mail e senha.');
      return;
    }

    try {
      const response = await fetch('https://api.conttalk.com.br/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, senha }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token); // Armazena o token no localStorage
        navigate('/dashboard'); // Redireciona para o dashboard
      } else {
        setError('Falha na autenticação. Verifique suas credenciais.');
      }
    } catch (err) {
      setError('Erro ao conectar ao servidor.');
    }
  };

  const handleForgotPassword = () => {
    alert('Redirecionar para a página de recuperação de senha');
  };

  return (
    <div className="login-container">
      <div className="left-panel">
        <h2>PROGRAMA DE INDICAÇÃO ACESSÓRIAS</h2>
        <p>Indique amigos e seja bonificado com <strong>+20 CNPJs</strong> em seu pacote!</p>
        <p>Confira as regras e participe:</p>
        <button className="indicar-amigos-button">INDICAR AMIGOS</button>
      </div>
      <div className="right-panel">
        <form onSubmit={handleLogin}>
          <img src={logo} alt="Logo" className="logo" />
          <input 
            type="email" 
            placeholder="Email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            className={error ? 'input-error' : ''}
          />
          <input 
            type="password" 
            placeholder="Senha" 
            value={senha} 
            onChange={(e) => setSenha(e.target.value)} 
            className={error ? 'input-error' : ''}
          />
          {error && <span className="error-text">{error}</span>}
          <button type="submit" className="submit-button">ENTRAR</button>
          <p>
            <button type="button" className="forgot-password" onClick={handleForgotPassword}>
              Esqueceu sua senha? Clique aqui
            </button>
          </p>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
