import React, { useState } from 'react';
import { Container, Box, Grid, Card, CardContent, Typography, TextField, InputAdornment, Button, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PaperIcon from '@mui/icons-material/Description';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CalculateIcon from '@mui/icons-material/Calculate';
import PersonIcon from '@mui/icons-material/Person';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChatIcon from '@mui/icons-material/Chat';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import BuildIcon from '@mui/icons-material/Build';
import { useNavigate } from 'react-router-dom';
import logo from './bg_logo_branca.png';

const categories = [
  { title: "Empresa", icon: <PaperIcon sx={{ fontSize: 60, color: '#FF6600' }} /> },
  { title: "Obrigações", icon: <AssignmentIcon sx={{ fontSize: 60, color: '#FF6600' }} /> },
  { title: "Ordem de Serviço", icon: <CalculateIcon sx={{ fontSize: 60, color: '#FF6600' }} /> },
  { title: "Controles", icon: <PersonIcon sx={{ fontSize: 60, color: '#FF6600' }} /> },
  { title: "Leads", icon: <CheckCircleIcon sx={{ fontSize: 60, color: '#FF6600' }} /> },
  { title: "Comunicador", icon: <ChatIcon sx={{ fontSize: 60, color: '#FF6600' }} /> },
  { title: "Financeiro", icon: <TouchAppIcon sx={{ fontSize: 60, color: '#FF6600' }} /> },
  { title: "Configurações", icon: <BuildIcon sx={{ fontSize: 60, color: '#FF6600' }} /> },
];

export default function KnowledgeBase() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');

  const handleBackClick = () => {
    navigate(-1); // Volta para a página anterior
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = () => {
    // Implementar a lógica de pesquisa aqui
  };

  const handleClearSearch = () => {
    setSearchQuery('');
  };

  return (
    <div>
      <Box sx={{ backgroundColor: '#2c3e50', color: '#fff', padding: '40px 0', textAlign: 'center', width: '100%' }}>
        <img
          src={logo}  // Usando o logo importado aqui
          alt="Logo"
          style={{ width: '150px', marginBottom: '20px' }}
        />
        <Typography variant="h4" gutterBottom>
          Treinamento
        </Typography>
      </Box>

      <Container maxWidth="lg">
        <Box sx={{ marginTop: 4, backgroundColor: '#fff', borderRadius: 1, padding: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={8}>
              <TextField
                placeholder="Procurar por assunto..."
                variant="outlined"
                fullWidth
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleSearchSubmit}
                sx={{ backgroundColor: '#FF6600', '&:hover': { backgroundColor: '#E65100' } }}
              >
                Confirmar Pesquisa
              </Button>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                variant="outlined"
                fullWidth
                onClick={handleClearSearch}
                sx={{ borderColor: '#FF6600', color: '#FF6600', '&:hover': { borderColor: '#E65100', color: '#E65100' } }}
              >
                Limpar
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ marginTop: 4 }}>
          <Grid container spacing={4}>
            {categories.map((category) => (
              <Grid item xs={12} sm={6} md={3} key={category.title}>
                <Card sx={{ height: '100%', border: '1px solid #FF6600', borderRadius: '8px', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)' } }}>
                  <CardContent sx={{ textAlign: 'center' }}>
                    {category.icon}
                    <Typography variant="h6" sx={{ marginTop: 2, fontWeight: 'bold' }}>
                      {category.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>

      <Button
        variant="contained"
        startIcon={<ArrowBackIcon />}
        sx={{
          position: 'fixed',
          bottom: 16,
          left: 16,
          backgroundColor: '#FF6600',
          '&:hover': { backgroundColor: '#E65100' },
          zIndex: 1000,
        }}
        onClick={handleBackClick}
      >
        Voltar
      </Button>
    </div>
  );
}
