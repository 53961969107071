import React, { useState } from 'react';
import {
  Container, Grid, TextField, Select, MenuItem, InputLabel, FormControl, Button, Typography, Tabs, Tab, Box, Card, CardContent, Accordion, AccordionSummary, AccordionDetails, List, ListItem, IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import './NovaObrigacao.css'; // Importa o CSS aqui


const NovaObrigacao = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [tasks, setTasks] = useState([
        { id: '1', name: 'Tarefa 1' },
        { id: '2', name: 'Tarefa 2' },
        { id: '3', name: 'Tarefa 3' }
    ]);
    const [newTaskName, setNewTaskName] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(null);

    const navigate = useNavigate();

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleSave = () => {
        // Lógica para salvar a obrigação
    };

    const handleNew = () => {
        // Lógica para criar uma nova obrigação
    };
    
    const handleBack = () => {
        navigate(-1); // Volta para a página anterior
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const newTasks = Array.from(tasks);
        const [reorderedTask] = newTasks.splice(result.source.index, 1);
        newTasks.splice(result.destination.index, 0, reorderedTask);

        setTasks(newTasks);
    };

    const handleTaskChange = (index, value) => {
        const newTasks = [...tasks];
        newTasks[index].name = value;
        setTasks(newTasks);
    };

    const handleAddTask = () => {
        if (newTaskName.trim() === '') return;

        const newTask = {
            id: (tasks.length + 1).toString(),
            name: newTaskName
        };
        setTasks([...tasks, newTask]);
        setNewTaskName('');
    };

    const handleDeleteTask = (index) => {
        const newTasks = tasks.filter((_, i) => i !== index);
        setTasks(newTasks);
    };

    const handleFileSelection = (folder) => {
        const files = [
            { name: 'Documento1.pdf', type: 'pdf' },
            { name: 'Documento2.docx', type: 'docx' },
            { name: 'Planilha.xlsx', type: 'xlsx' },
        ];
        setSelectedFiles(files);
        setSelectedFolder(folder);
    };

    const handleSaveFiles = () => {
        console.log('Arquivos salvos:', selectedFiles);
    };

    const clientesData = [
        { id: 1, nome: 'Cliente A', cnpj: '12.345.678/0001-90', status: 'Ativo' },
        { id: 2, nome: 'Cliente B', cnpj: '98.765.432/0001-10', status: 'Inativo' },
        { id: 3, nome: 'Cliente C', cnpj: '23.456.789/0001-11', status: 'Ativo' },
        { id: 4, nome: 'Cliente D', cnpj: '87.654.321/0001-22', status: 'Ativo' },
    ];

    const clientesColumns = [
        { accessorKey: 'nome', header: 'Nome do Cliente' },
        { accessorKey: 'cnpj', header: 'CNPJ' },
        { accessorKey: 'status', header: 'Status' },
    ];

    const meses = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];

    return (
        <Box className="nova-obrigacao-container">
            <Sidebar />
            <Box className="nova-obrigacao-content">
                <Container>
                    <Typography variant="h4" className="nova-obrigacao-title">
                        Cadastro de Nova Obrigação
                    </Typography>

                    <Box className="nova-obrigacao-tabs">
                        <Tabs
                            value={tabIndex}
                            onChange={handleTabChange}
                            aria-label="Cadastro de Nova Obrigação"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Tab label="Obrigações" />
                            <Tab label="Lista de Clientes para esta Obrigações" />
                            <Tab label="Documento para esta obrigação" />
                            <Tab label="Check-list " />
                        </Tabs>
                    </Box>

                    {tabIndex === 0 && (
                        <Card className="nova-obrigacao-card">
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={1}>
                                        <TextField fullWidth label="Código da empresa" variant="outlined" />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <InputMask
                                            mask="99.99.9999"
                                            alwaysShowMask={false}
                                            maskChar=""
                                        >
                                            {() => (
                                                <TextField
                                                    fullWidth
                                                    label="Classificação"
                                                    variant="outlined"
                                                    placeholder="00.00.0000"
                                                />
                                            )}
                                        </InputMask>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField fullWidth label="Nome da obrigação" variant="outlined" />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField fullWidth label="Mininome" variant="outlined" />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Departamento</InputLabel>
                                            <Select label="Departamento e Responsável">
                                                <MenuItem value="Contábil - Roger Vitor">Contábil - Roger Vitor</MenuItem>
                                                <MenuItem value="Financeiro - Roger Vitor">Financeiro - Roger Vitor</MenuItem>
                                                <MenuItem value="Fiscal - Roger Vitor">Fiscal - Roger Vitor</MenuItem>
                                                <MenuItem value="Legalização - Roger Vitor">Legalização - Roger Vitor</MenuItem>
                                                <MenuItem value="Pessoal - Roger Vitor">Pessoal - Roger Vitor</MenuItem>
                                                <MenuItem value="Férias">&nbsp; &nbsp; » férias</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Tipo do Serviço</InputLabel>
                                            <Select label="Selecione">
                                                <MenuItem value="Exemplo 1">Exemplo 1</MenuItem>
                                                <MenuItem value="Exemplo 1">Exemplo 1</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Grupo do Serviço</InputLabel>
                                            <Select label="Selecione">
                                                <MenuItem value="Exemplo 1">Exemplo 1</MenuItem>
                                                <MenuItem value="Exemplo 1">Exemplo 1</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Accordion className="nova-obrigacao-accordion">
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Entregas Mensais</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>
                                                {meses.map((mes, index) => (
                                                    <Grid item xs={12} sm={6} md={3} key={index}>
                                                        <FormControl fullWidth variant="outlined">
                                                            <InputLabel>Entrega {mes}</InputLabel>
                                                            <Select label={`Entrega ${mes}`}>
                                                                <MenuItem value="Não tem">Não tem</MenuItem>
                                                                {Array.from({ length: 31 }).map((_, day) => (
                                                                    <MenuItem value={day + 1} key={day}>
                                                                        Todo dia {day + 1}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField fullWidth label="Quantidade de dias para antecipar a entrega ao cliente" variant="outlined" />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField fullWidth label="Quantidade de dias para iniciar o serviço" variant="outlined" />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Tipo dos dias Antes</InputLabel>
                                            <Select label="Tipo dos dias Antes">
                                                <MenuItem value="Dias úteis">Dias úteis</MenuItem>
                                                <MenuItem value="Dias corridos">Dias corridos</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Prazos fixos em dias não úteis</InputLabel>
                                            <Select label="Prazos fixos em dias não úteis">
                                                <MenuItem value="Antecipar para o dia útil anterior">Antecipar para o dia útil anterior</MenuItem>
                                                <MenuItem value="Postergar para o próximo dia útil">Postergar para o próximo dia útil</MenuItem>
                                                <MenuItem value="Manter o dia exato">Manter o dia exato</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Sábado é útil?</InputLabel>
                                            <Select label="Sábado é útil?">
                                                <MenuItem value="N">Não</MenuItem>
                                                <MenuItem value="S">Sim</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Competência referente a</InputLabel>
                                            <Select label="Competência referente a">
                                                <MenuItem value="Mês anterior">Mês anterior</MenuItem>
                                                <MenuItem value="2 meses antes">2 meses antes</MenuItem>
                                                <MenuItem value="3 meses antes">3 meses antes</MenuItem>
                                                <MenuItem value="Ano anterior">Ano anterior</MenuItem>
                                                <MenuItem value="Ano atual">Ano atual</MenuItem>
                                                <MenuItem value="Mês atual">Mês atual</MenuItem>
                                                <MenuItem value="Mês seguinte">Mês seguinte</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Exigir Robô?</InputLabel>
                                            <Select label="Exigir Robô?">
                                                <MenuItem value="N">Não</MenuItem>
                                                <MenuItem value="S">Sim</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Passível de multa?</InputLabel>
                                            <Select label="Passível de multa?">
                                                <MenuItem value="N">Não</MenuItem>
                                                <MenuItem value="S">Sim</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Alerta guia não lida?</InputLabel>
                                            <Select label="Alerta guia não lida?">
                                                <MenuItem value="S">Sim</MenuItem>
                                                <MenuItem value="N">Não</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Obrigatório Check List?</InputLabel>
                                            <Select label="Obrigatório Check List?">
                                                <MenuItem value="S">Sim</MenuItem>
                                                <MenuItem value="N">Não</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Esfera</InputLabel>
                                            <Select label="Esfera">
                                                <MenuItem value="Federal">Federal</MenuItem>
                                                <MenuItem value="Estadual">Estadual</MenuItem>
                                                <MenuItem value="Municipal">Municipal</MenuItem>
                                                <MenuItem value="Interno">Interno</MenuItem>
                                                <MenuItem value="Outros">Outros</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Notificar o Cliente?</InputLabel>
                                            <Select label="Notificar o Cliente?">
                                                <MenuItem value="S">Sim</MenuItem>
                                                <MenuItem value="N">Não</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Serviço liberado no APP do cliente?</InputLabel>
                                            <Select label="Serviço liberado no APP do cliente?">
                                                <MenuItem value="S">Sim</MenuItem>
                                                <MenuItem value="N">Não</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Gerar para</InputLabel>
                                            <Select label="Gerar para">
                                                <MenuItem value="Matriz/Filial">Matriz/Filial</MenuItem>
                                                <MenuItem value="Apenas Matriz">Apenas Matriz</MenuItem>
                                                <MenuItem value="Apenas Filial">Apenas Filial</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Observação"
                                            variant="outlined"
                                            multiline
                                            rows={4}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                                        <Button variant="contained" color="success" onClick={handleSave}>
                                            Salvar
                                        </Button>
                                        <Button variant="contained" color="primary" onClick={handleNew}>
                                            Nova
                                        </Button>
                                        <Button variant="contained" color="warning" onClick={handleBack}>
                                            Voltar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )}
                    {tabIndex === 1 && (
                       <Card className="nova-obrigacao-card">
                       <CardContent>
                           <Grid container spacing={2}>
                               <Grid item xs={12} sm={6}>
                                   <Button
                                       variant="contained"
                                       onClick={() => handleFileSelection('Pasta Selecionada')}
                                   >
                                       Selecionar Pasta
                                   </Button>
                               </Grid>
                               {selectedFolder && (
                                   <>
                                       <Grid item xs={12}>
                                           <Typography variant="h6" sx={{ mt: 2 }}>
                                               Arquivos na pasta: {selectedFolder}
                                           </Typography>
                                           {selectedFiles.map((file, index) => (
                                               <Box
                                                   key={index}
                                                   sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                                               >
                                                   <Typography variant="body1">{file.name}</Typography>
                                               </Box>
                                           ))}
                                       </Grid>
                                       <Grid item xs={12}>
                                           <Button
                                               variant="contained"
                                               color="success"
                                               sx={{ mt: 2 }}
                                               onClick={handleSaveFiles}
                                           >
                                               Salvar Arquivos
                                           </Button>
                                       </Grid>
                                   </>
                               )}
                           </Grid>
                       </CardContent>
                   </Card>
               )}  
                    
                    {tabIndex === 2 && (
                        <Card className="nova-obrigacao-card">
                            <CardContent>
                            </CardContent>
                        </Card>
                    )}
                    {tabIndex === 3 && (
                        <Card className="nova-obrigacao-card">
                            <CardContent>
                                <Box className="nova-obrigacao-add-task">
                                    <TextField
                                        label="Nova Tarefa"
                                        variant="outlined"
                                        value={newTaskName}
                                        onChange={(e) => setNewTaskName(e.target.value)}
                                        fullWidth
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleAddTask}
                                        sx={{ ml: 2 }}
                                    >
                                        Adicionar Tarefa
                                    </Button>
                                </Box>

                                <DragDropContext onDragEnd={handleDragEnd}>
                                    <Droppable droppableId="tasks">
                                        {(provided) => (
                                            <List {...provided.droppableProps} ref={provided.innerRef}>
                                                {tasks.map((task, index) => (
                                                    <Draggable key={task.id} draggableId={task.id} index={index}>
                                                        {(provided) => (
                                                            <ListItem
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                className="nova-obrigacao-task-item"
                                                            >
                                                                <IconButton {...provided.dragHandleProps}>
                                                                    <DragIndicatorIcon />
                                                                </IconButton>
                                                                <TextField
                                                                    label={`Tarefa ${index + 1}`}
                                                                    variant="outlined"
                                                                    value={task.name}
                                                                    onChange={(e) => handleTaskChange(index, e.target.value)}
                                                                    fullWidth
                                                                    sx={{ ml: 2 }}
                                                                />
                                                                <IconButton
                                                                    edge="end"
                                                                    color="error"
                                                                    onClick={() => handleDeleteTask(index)}
                                                                    sx={{ ml: 2 }}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </ListItem>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </List>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </CardContent>
                        </Card>
                    )}
                </Container>
            </Box>
        </Box>
    );
};

export default NovaObrigacao;
