import React, { useState, useEffect } from 'react'; // Adiciona useEffect aqui
import {
  Container, Grid, TextField, Select, MenuItem, InputLabel, FormControl,
  Button, Typography, Autocomplete, Chip, Card, CardContent, Tabs, Tab, Box, 
  IconButton, Modal, Paper, Menu, TableCell, ListItemIcon, ListItemText, 
  ListItem, Snackbar, Alert, TableContainer, Table, TableHead, TableRow, TableBody
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import Sidebar from './Sidebar';
import InputMask from 'react-input-mask';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';

import './NovaEmpresa.css'; // Importa o CSS aqui


// função para descobrir se e cpf ou cnpj para mostrar a mascara 

// Função para buscar dados do CNPJ
const fetchCnpjData = async (cnpj, setNome, setNomeFantasia, setCep, setLogradouro, setBairro, setCidade, setUf, setSnackbarMessage, setSnackbarSeverity, setSnackbarOpen) => {
  try {
    const response = await axios.get(`https://www.receitaws.com.br/v1/cnpj/${cnpj}`);
    const data = response.data;

    if (data.status === 'ERROR') {
      setSnackbarMessage('Erro ao buscar informações do CNPJ.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else {
      // Aqui você pode preencher os campos com os dados recebidos
      setNome(data.nome);
      setNomeFantasia(data.fantasia);
      setCep(data.cep);
      setLogradouro(data.logradouro);
      setBairro(data.bairro);
      setCidade(data.municipio);
      setUf(data.uf);
      setSnackbarMessage('Informações carregadas com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
  } catch (error) {
    console.error('Erro ao buscar dados do CNPJ:', error);
    setSnackbarMessage('Erro ao buscar CNPJ.');
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  }
};



const NovaEmpresa = () => {
  const [estados, setEstados] = useState([]); // Para armazenar a lista de estados
  const [cidades, setCidades] = useState([]); // Para armazenar a lista de cidades
  const [uf, setUf] = useState(''); // Armazena o estado selecionado
  const [cidade, setCidade] = useState(''); // Armazena a cidade selecionada

  // Função para buscar os estados
  const fetchEstados = async () => {
    try {
      const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
      setEstados(response.data.sort((a, b) => a.nome.localeCompare(b.nome))); // Organizar por nome
    } catch (error) {
      console.error("Erro ao buscar estados:", error);
    }
  };

  // Função para buscar cidades com base no estado selecionado
  const fetchCidades = async (uf) => {
    try {
      const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`);
      setCidades(response.data.sort((a, b) => a.nome.localeCompare(b.nome))); // Organizar por nome
    } catch (error) {
      console.error("Erro ao buscar cidades:", error);
    }
  };

  // useEffect para buscar estados ao carregar a página
  useEffect(() => {
    fetchEstados();
  }, []);

  // useEffect para buscar cidades sempre que o estado (UF) mudar
  useEffect(() => {
    if (uf) {
      fetchCidades(uf);
    }
  }, [uf]);
  
  const [tabIndex, setTabIndex] = useState(0);
  const [grupoCliente, setGrupoCliente] = useState([]);
  const [segmento, setSegmento] = useState([]);
  const [categoria, setCategoria] = useState('');
  const [status, setStatus] = useState('');
  const [statusComplementar, setStatusComplementar] = useState('');
  const [escritorio, setEscritorio] = useState('');
  const [tipo, setTipo] = useState('');
  const [tags, setTags] = useState([]);
  const [contatos, setContatos] = useState([]);
  const [palavrasChave, setPalavrasChave] = useState([]);
  const [redesSociais, setRedesSociais] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [observacoes, setObservacoes] = useState([]);
  const [openVigenciaModal, setOpenVigenciaModal] = useState(false);
  const [openItemModal, setOpenItemModal] = useState(false);
  const [openChecklist, setOpenChecklist] = useState(false);
  const [novaVigencia, setNovaVigencia] = useState({
    competencia: '',
    dataCadastro: new Date().toISOString().split('T')[0],
  });
  const [newItemName, setNewItemName] = useState('');
  const [modalField, setModalField] = useState('');
  const [rows, setRows] = useState([
    { id: 1, regimeTf: 'Simples', regimeTe: 'Simples', regimeTM: 'Simples', vigencia: '01/2023' },
    { id: 2, regimeTf: 'Simples', regimeTe: 'Simples', regimeTM: 'Simples', vigencia: '01/2023' },
    { id: 3, regimeTf: 'Simples', regimeTe: 'Simples', regimeTM: 'Simples', vigencia: '01/2023' },
  ]);
  const [selectedObligations, setSelectedObligations] = useState([]);
  const [openObligationModal, setOpenObligationModal] = useState(false);
  const [obligationRows, setObligationRows] = useState([
    { id: 1, name: 'Obrigação 1' },
    { id: 2, name: 'Obrigação 2' },
    { id: 3, name: 'Obrigação 3' },
  ]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState('');  // Mensagem do Snackbar
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Severidade do Snackbar
  


  // Campos adicionais para integração com o backend
  const [nome, setNome] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [apelido, setApelido] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [inscricaoEstadual, setInscricaoEstadual] = useState('');
  const [inscricaoMunicipal, setInscricaoMunicipal] = useState('');
  const [cei, setCei] = useState('');
  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numeroLogradouro, setNumeroLogradouro] = useState('');
  const [complementoLogradouro, setComplementoLogradouro] = useState('');
  const [bairro, setBairro] = useState('');
  const [website, setWebsite] = useState('');
  const [dataInscricaoMunicipal, setDataInscricaoMunicipal] = useState('');
  const [honorario, setHonorario] = useState('');
  const [isentaInscricaoEstadual, setIsentaInscricaoEstadual] = useState(false);
  
  const openMenu = Boolean(anchorEl);

  const columns = [
    { id: 'dataCadastro', header: 'Data Cadastro', accessorKey: 'dataCadastro', width: 150 },
    { id: 'regimeTf', header: 'Regime Tributario Federal', accessorKey: 'regimeTf', width: 200 },
    { id: 'regimeTe', header: 'Regime Tributario Estadual', accessorKey: 'regimeTe', width: 200 },
    { id: 'regimeTM', header: 'Regime Tributario Municipal', accessorKey: 'regimeTM', width: 200 },
    { id: 'vigencia', header: 'Vigência', accessorKey: 'vigencia', width: 100 },
    {
      id: 'acoes',
      header: 'Ações',
      accessorKey: 'acoes',
      width: 50,
      Cell: ({ row }) => (
        <IconButton onClick={(event) => handleMenuClick(event, row.original)}>
          <MoreVertIcon />
        </IconButton>
      ),
    }
  ];

  const obligationColumns = [
    {
      id: 'select',
      header: 'Selecionar',
      accessorKey: 'select',
      width: 50,
      Cell: ({ row }) => (
        <Checkbox
          checked={selectedObligations.includes(row.original.id)}
          onChange={(e) => handleObligationSelect(e, row.original.id)}
        />
      ),
    },
    { id: 'name', header: 'Obrigações', accessorKey: 'name', width: 300 },
  ];

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleOpenModal = (field) => {
    setModalField(field);
    setOpenItemModal(true);
  };

  const handleCloseModal = () => {
    setNewItemName('');
    setOpenItemModal(false);
  };

  const handleSaveNewItem = () => {
    const updateState = (setter, state) => setter([...state, newItemName]);
    switch (modalField) {
      case 'grupoCliente':
        updateState(setGrupoCliente, grupoCliente);
        break;
      case 'status':
        updateState(setStatus, status);
        break;
      case 'statusComplementar':
        updateState(setStatusComplementar, statusComplementar);
        break;
      case 'escritorio':
        updateState(setEscritorio, escritorio);
        break;
      case 'categoria':
        updateState(setCategoria, categoria);
        break;
      case 'segmento':
        updateState(setSegmento, segmento);
        break;
      case 'tipo':
        updateState(setTipo, tipo);
        break;
      default:
        break;
    }
    handleCloseModal();
  };

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEdit = () => {
    console.log('Editar', selectedRow);
    handleMenuClose();
  };

  const handleDuplicate = () => {
    setRows([...rows, { ...selectedRow, id: rows.length + 1 }]);
    handleMenuClose();
  };

  const handleDelete = () => {
    setRows(rows.filter((row) => row.id !== selectedRow.id));
    handleMenuClose();
  };

  const handleRowClick = (row) => {
    setSelectedRow(row);
    setOpenChecklist(true);
  };

  const handleAddContato = () => {
    setContatos([...contatos, { nome: '', cargo: '', celular: '', email: '', tipoEmail: '', obs: '' }]);
  };

  const handleRemoveContato = (index) => {
    const newContatos = contatos.filter((_, i) => i !== index);
    setContatos(newContatos);
  };

  const handleAddRedeSocial = () => {
    setRedesSociais([...redesSociais, { nome: '', url: '' }]);
  };

  const handleRemoveRedeSocial = (index) => {
    const newRedesSociais = redesSociais.filter((_, i) => i !== index);
    setRedesSociais(newRedesSociais);
  };

  const handleAddDepartamento = () => {
    setDepartamentos([...departamentos, { nome: '', responsavel: '' }]);
  };

  const handleRemoveDepartamento = (index) => {
    const newDepartamentos = departamentos.filter((_, i) => i !== index);
    setDepartamentos(newDepartamentos);
  };

  const handleOpenVigenciaModal = () => {
    setOpenVigenciaModal(true);
  };

  const handleCloseVigenciaModal = () => {
    setOpenVigenciaModal(false);
  };

  const handleSaveVigencia = () => {
    setRows([...rows, { id: rows.length + 1, nome: `Obrigação ${rows.length + 1}`, vigencia: novaVigencia.competencia }]);
    handleCloseVigenciaModal();
  };

  const handleOpenObligationModal = () => {
    setOpenObligationModal(true);
  };

  const handleCloseObligationModal = () => {
    setOpenObligationModal(false);
  };

  const handleSaveObligations = () => {
    console.log('Obrigações salvas:', selectedObligations);
    handleCloseObligationModal();
  };

  const handleObligationSelect = (event, id) => {
    const newSelected = event.target.checked
      ? [...selectedObligations, id]
      : selectedObligations.filter((obligationId) => obligationId !== id);
    setSelectedObligations(newSelected);
  };

  const handleSave = () => {
    // Exemplo de como enviar os dados ao backend
    const data = {
      nome,
      nomeFantasia,
      apelido,
      cpfCnpj,
      inscricaoEstadual,
      inscricaoMunicipal,
      cei,
      cep,
      logradouro,
      numeroLogradouro,
      complementoLogradouro,
      bairro,
      cidade,
      uf,
      website,
      dataInscricaoMunicipal,
      honorario,
      isentaInscricaoEstadual,
      grupoCliente,
      status,
      statusComplementar,
      escritorio,
      categoria,
      segmento,
      tipo,
      contatos,
      palavrasChave,
      redesSociais,
      departamentos,
      observacoes,
    };

    axios.post('https://api.conttalk.com.br/api/clientes', data)
      .then((response) => {
        console.log('Empresa salva com sucesso!', response.data);
        setSnackbarMessage('Empresa salva com sucesso!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error('Erro ao salvar a empresa:', error);
        setSnackbarMessage('Erro ao salvar a empresa.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box className="nova-empresa-page">
      <Sidebar />
      <Box className="main-content">
        <Container className="container">
          <Typography className="title" variant="h5" gutterBottom>
            Cadastro de Cliente
          </Typography>

          {/* Tabs */}
          <Box className="tabs-container">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="Cadastro de Empresa Tabs"
              variant="scrollable"
              scrollButtons="auto"
              className="tab-label"
            >
              <Tab label="Dados Básicos" />
              <Tab label="Status" />
              <Tab label="Contatos na Empresa" />
              <Tab label="Campo de pesquisa" />
              <Tab label="Anotações" />
              <Tab label="Rede Social" />
              <Tab label="Responsáveis pelos Departamentos" />
              <Tab label="Lista de obrigações para este cliente" />
            </Tabs>
          </Box>

          {/* Conteúdo das Tabs */}
          {tabIndex === 0 && (
            <Card className="card">
              <CardContent>
              
                <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                <TextField 
                  className="form-control" 
                  fullWidth 
                  label="Nome" 
                  variant="outlined" 
                  value={nome} // Campo preenchido automaticamente
                  onChange={(e) => setNome(e.target.value)} // Permite edição
                />
              </Grid>
                  <Grid item xs={12} sm={6}>
                  <TextField 
                    className="form-control" 
                    fullWidth 
                    label="Nome Fantasia" 
                    variant="outlined" 
                    value={nomeFantasia} // Campo preenchido automaticamente
                    onChange={(e) => setNomeFantasia(e.target.value)} // Permite edição
                  />
                </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField 
                      className="form-control" 
                      fullWidth 
                      label="Apelido" 
                      variant="outlined" 
                      value={apelido}
                      onChange={(e) => setApelido(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <InputMask
                    mask="99.999.999/9999-99"
                    value={cpfCnpj}
                    onChange={(e) => {
                      const cnpj = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
                      setCpfCnpj(e.target.value);

                      if (cnpj.length === 14) {
                        fetchCnpjData(
                          cnpj, 
                          setNome, 
                          setNomeFantasia, 
                          setCep, 
                          setLogradouro, 
                          setBairro, 
                          setCidade, 
                          setUf, 
                          setSnackbarMessage, 
                          setSnackbarSeverity, 
                          setSnackbarOpen
                        ); // Chama a API quando o CNPJ estiver completo
                      }
                    }}
                    disabled={false}
                    maskChar=""
                  >
                    {() => (
                      <TextField label="CPF/CNPJ" fullWidth variant="outlined" />
                    )}
                  </InputMask>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField 
                      className="form-control" 
                      fullWidth 
                      label="Inscrição Estadual" 
                      variant="outlined" 
                      value={inscricaoEstadual}
                      onChange={(e) => setInscricaoEstadual(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField 
                      className="form-control" 
                      fullWidth 
                      label="Inscrição Municipal" 
                      variant="outlined" 
                      value={inscricaoMunicipal}
                      onChange={(e) => setInscricaoMunicipal(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField 
                      className="form-control" 
                      fullWidth 
                      label="CEI" 
                      variant="outlined" 
                      value={cei}
                      onChange={(e) => setCei(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
  <TextField 
    className="form-control" 
    fullWidth 
    label="CEP" 
    variant="outlined" 
    value={cep} // Campo preenchido automaticamente
    onChange={(e) => setCep(e.target.value)} // Permite edição
  />
</Grid>
<Grid item xs={12} sm={6}>
  <TextField 
    className="form-control" 
    fullWidth 
    label="Logradouro" 
    variant="outlined" 
    value={logradouro} // Campo preenchido automaticamente
    onChange={(e) => setLogradouro(e.target.value)} // Permite edição
  />
</Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField 
                      className="form-control" 
                      fullWidth 
                      label="Número" 
                      variant="outlined" 
                      value={numeroLogradouro}
                      onChange={(e) => setNumeroLogradouro(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField 
                      className="form-control" 
                      fullWidth 
                      label="Complemento" 
                      variant="outlined" 
                      value={complementoLogradouro}
                      onChange={(e) => setComplementoLogradouro(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
  <TextField 
    className="form-control" 
    fullWidth 
    label="Bairro" 
    variant="outlined" 
    value={bairro} // Campo preenchido automaticamente
    onChange={(e) => setBairro(e.target.value)} // Permite edição
  />
</Grid>
                 
<Grid item xs={12} sm={2}>
  <FormControl className="form-control" fullWidth>
    <InputLabel>UF</InputLabel>
    <Select
      label="UF"
      value={uf}
      onChange={(e) => setUf(e.target.value)} // Permite alteração
    >
      {estados.map((estado) => (
        <MenuItem key={estado.sigla} value={estado.sigla}>
          {estado.nome}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>

<Grid item xs={12} sm={6}>
  <TextField 
    className="form-control" 
    fullWidth 
    label="Cidade" 
    variant="outlined" 
    value={cidade} // Campo preenchido automaticamente
    onChange={(e) => setCidade(e.target.value)} // Permite edição
  />
</Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField 
                      className="form-control" 
                      fullWidth 
                      label="Website" 
                      variant="outlined" 
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField 
                      className="form-control" 
                      fullWidth 
                      label="Honorário" 
                      variant="outlined" 
                      value={honorario}
                      onChange={(e) => setHonorario(e.target.value)}
                    />
                  </Grid>
                  
                </Grid>
              </CardContent>
            </Card>
          )}

{tabIndex === 1 && (
  <Card className="card">
    <CardContent>
      <Grid container spacing={4}> {/* Aumenta o espaçamento entre os itens */}
        <Grid item xs={12} sm={6} style={{ marginBottom: '16px' }}> {/* Margem extra */}
          <FormControl className="form-control" fullWidth>
            <InputLabel>Status</InputLabel>
            <div style={{ position: 'relative' }}>
              <Select
                label="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                fullWidth
              >
                <MenuItem value="Ativo">Ativo</MenuItem>
                <MenuItem value="Inativo">Inativo</MenuItem>
                <MenuItem value="Suspenso">Suspenso</MenuItem>
              </Select>
              <IconButton
                onClick={() => handleOpenModal('status')}
                className="icon-button"
                style={{
                  position: 'absolute',
                  top: '-22px',
                  right: '0px',
                  zIndex: 1,
                  padding: 1,
                }}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </div>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} style={{ marginBottom: '16px' }}>
          <FormControl className="form-control" fullWidth>
            <InputLabel>Status Complementar</InputLabel>
            <div style={{ position: 'relative' }}>
              <Select
                label="Status Complementar"
                value={statusComplementar}
                onChange={(e) => setStatusComplementar(e.target.value)}
                fullWidth
              >
                <MenuItem value="Baixado">Baixado</MenuItem>
                <MenuItem value="Em Abertura">Em Abertura</MenuItem>
                <MenuItem value="Em Baixa">Em Baixa</MenuItem>
                <MenuItem value="Em Carteira">Em Carteira</MenuItem>
              </Select>
              <IconButton
                onClick={() => handleOpenModal('statusComplementar')}
                className="icon-button"
                style={{
                  position: 'absolute',
                  top: '-22px',
                  right: '0px',
                  zIndex: 1,
                  padding: 1,
                }}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </div>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} style={{ marginBottom: '16px' }}>
          <FormControl className="form-control" fullWidth>
            <InputLabel>Escritório</InputLabel>
            <div style={{ position: 'relative' }}>
              <Select
                label="Escritório"
                value={escritorio}
                onChange={(e) => setEscritorio(e.target.value)}
                fullWidth
              >
                <MenuItem value="">
                  <em>Nenhum</em>
                </MenuItem>
                <MenuItem value="Escritório A">Escritório A</MenuItem>
                <MenuItem value="Escritório B">Escritório B</MenuItem>
              </Select>
              <IconButton
                onClick={() => handleOpenModal('escritorio')}
                className="icon-button"
                style={{
                  position: 'absolute',
                  top: '-22px',
                  right: '0px',
                  zIndex: 1,
                  padding: 1,
                }}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </div>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} style={{ marginBottom: '16px' }}>
          <FormControl className="form-control" fullWidth>
            <InputLabel>Categoria</InputLabel>
            <div style={{ position: 'relative' }}>
              <Select
                label="Categoria"
                value={categoria}
                onChange={(e) => setCategoria(e.target.value)}
                fullWidth
              >
                <MenuItem value="Comércio">Comércio</MenuItem>
                <MenuItem value="Indústria">Indústria</MenuItem>
                <MenuItem value="Serviço">Serviço</MenuItem>
                <MenuItem value="Rural">Rural</MenuItem>
              </Select>
              <IconButton
                onClick={() => handleOpenModal('categoria')}
                className="icon-button"
                style={{
                  position: 'absolute',
                  top: '-22px',
                  right: '0px',
                  zIndex: 1,
                  padding: 1,
                }}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </div>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} style={{ marginBottom: '16px' }}>
          <FormControl className="form-control" fullWidth>
            <InputLabel>Segmento</InputLabel>
            <div style={{ position: 'relative' }}>
              <Select
                label="Segmento"
                multiple
                value={segmento}
                onChange={(e) => setSegmento(e.target.value)}
                renderValue={(selected) => selected.join(', ')}
                fullWidth
              >
                <MenuItem value="Contabilidade">Contabilidade</MenuItem>
                <MenuItem value="Farmácia">Farmácia</MenuItem>
                <MenuItem value="Supermercado">Supermercado</MenuItem>
                <MenuItem value="Auto Peças">Auto Peças</MenuItem>
              </Select>
              <IconButton
                onClick={() => handleOpenModal('segmento')}
                className="icon-button"
                style={{
                  position: 'absolute',
                  top: '-22px',
                  right: '0px',
                  zIndex: 1,
                  padding: 1,
                }}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </div>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} style={{ marginBottom: '16px' }}>
          <FormControl className="form-control" fullWidth>
            <InputLabel>Tipo</InputLabel>
            <div style={{ position: 'relative' }}>
              <Select
                label="Tipo"
                value={tipo}
                onChange={(e) => setTipo(e.target.value)}
                fullWidth
              >
                <MenuItem value="Fixo">Fixo</MenuItem>
                <MenuItem value="Eventual">Eventual</MenuItem>
              </Select>
              <IconButton
                onClick={() => handleOpenModal('tipo')}
                className="icon-button"
                style={{
                  position: 'absolute',
                  top: '-22px',
                  right: '0px',
                  zIndex: 1,
                  padding: 1,
                }}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </div>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} style={{ marginBottom: '16px' }}>
          <FormControl className="form-control" fullWidth>
            <InputLabel>Tags</InputLabel>
            <div style={{ position: 'relative' }}>
              <Select
                label="Tags"
                multiple
                value={segmento}
                onChange={(e) => setSegmento(e.target.value)}
                renderValue={(selected) => selected.join(', ')}
                fullWidth
              >
               
              </Select>
              <IconButton
                onClick={() => handleOpenModal('segmento')}
                className="icon-button"
                style={{
                  position: 'absolute',
                  top: '-22px',
                  right: '0px',
                  zIndex: 1,
                  padding: 1,
                }}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </div>
          </FormControl>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
)}



        

         

          {tabIndex === 2 && (
            <Card className="card">
              <CardContent>
                
                {contatos.map((contato, index) => (
                  <Grid container spacing={2} key={index} alignItems="center" sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        className="form-control"
                        fullWidth
                        label="Nome"
                        variant="outlined"
                        value={contato.nome}
                        onChange={(e) => {
                          const newContatos = [...contatos];
                          newContatos[index].nome = e.target.value;
                          setContatos(newContatos);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        className="form-control"
                        fullWidth
                        label="Cargo"
                        variant="outlined"
                        value={contato.cargo}
                        onChange={(e) => {
                          const newContatos = [...contatos];
                          newContatos[index].cargo = e.target.value;
                          setContatos(newContatos);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        className="form-control"
                        fullWidth
                        label="Celular"
                        variant="outlined"
                        value={contato.celular}
                        onChange={(e) => {
                          const newContatos = [...contatos];
                          newContatos[index].celular = e.target.value;
                          setContatos(newContatos);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        className="form-control"
                        fullWidth
                        label="E-mail"
                        variant="outlined"
                        value={contato.email}
                        onChange={(e) => {
                          const newContatos = [...contatos];
                          newContatos[index].email = e.target.value;
                          setContatos(newContatos);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <IconButton className="icon-button" color="error" onClick={() => handleRemoveContato(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl className="form-control" fullWidth>
                        <InputLabel>Tipo de E-mail</InputLabel>
                        <Select
                          label="Tipo de E-mail"
                          value={contato.tipoEmail}
                          onChange={(e) => {
                            const newContatos = [...contatos];
                            newContatos[index].tipoEmail = e.target.value;
                            setContatos(newContatos);
                          }}
                        >
                          <MenuItem value="Contábil">Contábil</MenuItem>
                          <MenuItem value="Financeiro">Financeiro</MenuItem>
                          <MenuItem value="Fiscal">Fiscal</MenuItem>
                          <MenuItem value="Legalização">Legalização</MenuItem>
                          <MenuItem value="Licitação">Licitação</MenuItem>
                          <MenuItem value="Pessoal">Pessoal</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <TextField
                        className="form-control"
                        fullWidth
                        label="Anotações"
                        variant="outlined"
                        multiline
                        rows={2}
                        value={contato.obs}
                        onChange={(e) => {
                          const newContatos = [...contatos];
                          newContatos[index].obs = e.target.value;
                          setContatos(newContatos);
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAddContato}
                  className="add-button"
                >
                  Adicionar Contato
                </Button>
              </CardContent>
            </Card>
          )}

          {tabIndex === 3 && (
            <Card className="card">
              <CardContent>
               
                <Autocomplete
                  multiple
                  freeSolo
                  options={[]}
                  value={palavrasChave}
                  onChange={(event, newValue) => {
                    setPalavrasChave(newValue);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="form-control"
                      variant="outlined"
                      label="Termo de pesquisa"
                      placeholder="Adicionar termo de pesquisa"
                    />
                  )}
                />
              </CardContent>
            </Card>
          )}

          {tabIndex === 4 && (
            <Card className="card">
              <CardContent>
               
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      className="form-control"
                      fullWidth
                      label="Data"
                      variant="outlined"
                      value={new Date().toISOString().slice(0, 10)}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField className="form-control" fullWidth label="Departamento" variant="outlined" />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField className="form-control" fullWidth label="Usuário" variant="outlined" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className="form-control"
                      fullWidth
                      label="Descrição"
                      variant="outlined"
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} className="actions">
                    <Button className="action-button" variant="contained">
                      Anexar Arquivo
                    </Button>
                    <Button className="action-button" variant="contained">
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
                <TableContainer component={Paper} className="table-container">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Data</TableCell>
                        <TableCell>Departamento</TableCell>
                        <TableCell>Usuário</TableCell>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Anexo</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {observacoes.map((obs, index) => (
                        <TableRow key={index}>
                          <TableCell>{obs.data}</TableCell>
                          <TableCell>{obs.departamento}</TableCell>
                          <TableCell>{obs.usuario}</TableCell>
                          <TableCell>{obs.descricao}</TableCell>
                          <TableCell>{obs.anexo}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}

          {tabIndex === 5 && (
            <Card className="card">
              <CardContent>
               
                {redesSociais.map((redeSocial, index) => (
                  <Grid container spacing={2} key={index} alignItems="center" className="social-row">
                    <Grid item xs={12} sm={3}>
                      <FormControl className="form-control" fullWidth>
                        <InputLabel>Rede Social</InputLabel>
                        <Select
                          label="Rede Social"
                          value={redeSocial.nome}
                          onChange={(e) => {
                            const newRedesSociais = [...redesSociais];
                            newRedesSociais[index].nome = e.target.value;
                            setRedesSociais(newRedesSociais);
                          }}
                        >
                          {['Facebook', 'Instagram', 'LinkedIn', 'Twitter', 'WhatsApp', 'YouTube', 'TikTok'].map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextField
                        className="form-control"
                        fullWidth
                        label="URL"
                        variant="outlined"
                        value={redeSocial.url}
                        onChange={(e) => {
                          const newRedesSociais = [...redesSociais];
                          newRedesSociais[index].url = e.target.value;
                          setRedesSociais(newRedesSociais);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <IconButton className="icon-button" color="error" onClick={() => handleRemoveRedeSocial(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAddRedeSocial}
                  className="add-button"
                >
                  Adicionar Rede Social
                </Button>
              </CardContent>
            </Card>
          )}

          {tabIndex === 6 && (
            <Card className="card">
              <CardContent>
                
                {departamentos.map((departamento, index) => (
                  <Grid container spacing={2} key={index} alignItems="center" className="department-row">
                    <Grid item xs={12} sm={5}>
                      <TextField
                        className="form-control"
                        fullWidth
                        label="Nome do Departamento"
                        variant="outlined"
                        value={departamento.nome}
                        onChange={(e) => {
                          const newDepartamentos = [...departamentos];
                          newDepartamentos[index].nome = e.target.value;
                          setDepartamentos(newDepartamentos);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        className="form-control"
                        fullWidth
                        label="Nome do Responsável"
                        variant="outlined"
                        value={departamento.responsavel}
                        onChange={(e) => {
                          const newDepartamentos = [...departamentos];
                          newDepartamentos[index].responsavel = e.target.value;
                          setDepartamentos(newDepartamentos);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <IconButton className="icon-button" color="error" onClick={() => handleRemoveDepartamento(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAddDepartamento}
                  className="add-button"
                >
                  Adicionar Departamento
                </Button>
              </CardContent>
            </Card>
          )}

          {/* Conteúdo das Tabs */}
          {tabIndex === 7 && (
            <Card className="card">
              <CardContent>
               
                <Grid container spacing={2} justifyContent="flex-end" className="button-container">
                  <Button variant="contained" startIcon={<AddIcon />} onClick={handleOpenVigenciaModal}>
                    Nova Vigência
                  </Button>
                </Grid>
                <Box className="table-container">
                  <MaterialReactTable
                    columns={columns}
                    data={rows}
                    enableRowSelection={false}
                    onRowClick={({ row }) => handleRowClick(row.original)}
                    enableColumnDragging
                  />
                </Box>
                
              </CardContent>
            </Card>
          )}

          {/* Modal para Adicionar Nova Vigência */}
          <Modal
            open={openVigenciaModal}
            onClose={handleCloseVigenciaModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modal-box">
              
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="form-control"
                    fullWidth
                    label="Data do Cadastro"
                    variant="outlined"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={novaVigencia.dataCadastro}
                    disabled
                    sx={{ mt: 2 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputMask
                    mask="99/9999"
                    value={novaVigencia.competencia}
                    onChange={(e) => setNovaVigencia({ ...novaVigencia, competencia: e.target.value })}
                  >
                    {() => <TextField fullWidth label="Vigência referente à competência de mês/ano" variant="outlined" sx={{ mt: 2 }} />}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl className="form-control" fullWidth sx={{ mt: 2 }}>
                    <InputLabel>Regime Tributário Federal</InputLabel>
                    <Select
                      value={novaVigencia.regimeFederal}
                      onChange={(e) => setNovaVigencia({ ...novaVigencia, regimeFederal: e.target.value })}
                      label="Regime Tributário Federal"
                    >
                      <MenuItem value="Simples Nacional">Simples Nacional</MenuItem>
                      <MenuItem value="Lucro Presumido">Lucro Presumido</MenuItem>
                      <MenuItem value="Lucro Real">Lucro Real</MenuItem>
                      <MenuItem value="Outro">Outro</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl className="form-control" fullWidth sx={{ mt: 2 }}>
                    <InputLabel>Regime Tributário Estadual</InputLabel>
                    <Select
                      value={novaVigencia.regimeEstadual}
                      onChange={(e) => setNovaVigencia({ ...novaVigencia, regimeEstadual: e.target.value })}
                      label="Regime Tributário Estadual"
                    >
                      <MenuItem value="Substituição Tributária">Substituição Tributária</MenuItem>
                      <MenuItem value="Regime Normal">Regime Normal</MenuItem>
                      <MenuItem value="Outro">Outro</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl className="form-control" fullWidth sx={{ mt: 2 }}>
                    <InputLabel>Regime Tributário Municipal</InputLabel>
                    <Select
                      value={novaVigencia.regimeMunicipal}
                      onChange={(e) => setNovaVigencia({ ...novaVigencia, regimeMunicipal: e.target.value })}
                      label="Regime Tributário Municipal"
                    >
                      <MenuItem value="ISSQN Fixo">ISSQN Fixo</MenuItem>
                      <MenuItem value="ISSQN Variável">ISSQN Variável</MenuItem>
                      <MenuItem value="Outro">Outro</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                variant="contained"
                sx={{ mt: 2 }}
                onClick={handleSaveVigencia}
              >
                Salvar
              </Button>
            </Box>
          </Modal>

          {/* Modal para Adicionar Novos Itens */}
          <Modal
            open={openItemModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modal-box">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Nova {modalField}
              </Typography>
              <TextField
                className="form-control"
                fullWidth
                label={`Nome do Novo ${modalField}`}
                variant="outlined"
                value={newItemName}
                onChange={(e) => setNewItemName(e.target.value)}
                sx={{ mt: 2 }}
              />
              
              <Button
                variant="contained"
                sx={{ mt: 2 }}
                onClick={handleSaveNewItem}
              >
                Salvar
              </Button>
            </Box>
          </Modal>

          {/* Modal de Checklist de Atividades */}
          <Modal
            open={openChecklist}
            onClose={() => setOpenChecklist(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modal-box">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Checklist de Atividades para {selectedRow?.regimeTf}
              </Typography>
              <Box sx={{ height: 400, width: '100%', mt: 3 }}>
                <MaterialReactTable
                  columns={columns}
                  data={rows}
                  enableColumnDragging
                />
              </Box>
              <Button variant="contained" sx={{ mt: 2 }} onClick={() => setOpenChecklist(false)}>
                Salvar
              </Button>
            </Box>
          </Modal>

          {/* Modal para Adicionar Obrigações */}
          <Modal
            open={openObligationModal}
            onClose={handleCloseObligationModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modal-box">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Selecionar Obrigações
              </Typography>
              <Box sx={{ height: 400, width: '100%', mt: 3 }}>
                <MaterialReactTable
                  columns={obligationColumns}
                  data={obligationRows}
                  enableColumnDragging
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button variant="contained" color="error" onClick={handleCloseObligationModal}>
                  Fechar
                </Button>
                <Button variant="contained" color="primary" onClick={handleSaveObligations}>
                  Salvar
                </Button>
              </Box>
            </Box>
          </Modal>

         

          {/* Botão para salvar empresa */}
          <Grid container spacing={2} justifyContent="flex-end" className="button-container" sx={{ mt: 2 }}>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleSave}
            >
              Salvar cliente
            </Button>
          </Grid>
        </Container>
      </Box>

      {/* Snackbar para feedback do usuário */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NovaEmpresa;
