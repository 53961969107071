import React, { useMemo, useState } from 'react';
import Sidebar from './Sidebar';
import { Box, Button, TextField, Typography, IconButton, Tooltip, Container, Grid, Modal, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { MaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import './leads.css'; // Importa o CSS aqui

const initialRows = [
  // Dados de exemplo, adapte conforme necessário
  { id: 1, nome: 'teste', criadoEm: '20/08/2024 07:22', modificadoEm: '20/08/2024 07:22', origem: 'SITE', campanha: 'B66D-E0', responsavel: 'Usuário Master', emails: 'josiel@contco.com.br', telefones: '(11) 99999-9999', status: 'Pendente' },
  // Adicione mais dados de exemplo aqui
];

const statusCards = [
  { label: 'Pendente', value: 2, color: '#FFEB3B' }, // Amarelo
  { label: 'Proposta', value: 1, color: '#4CAF50' },  // Verde
  { label: 'Negociação', value: 3, color: '#2196F3' }, // Azul
  { label: 'Apresentação', value: 4, color: '#F44336' }, // Vermelho
  { label: 'Contato', value: 4, color: '#F44336' }, // Vermelho
  { label: 'Reagendado', value: 3, color: '#2196F3' }, // Azul
  { label: 'Tentativa', value: 1, color: '#4CAF50' },  // Verde
  { label: 'Fechado', value: 1, color: '#4CAF50' },  // Verde
  { label: 'Declinado', value: 2, color: '#FFEB3B' }, // Amarelo

];

const LeadsPage = () => {
  const [rows, setRows] = useState(initialRows);
  const [searchText, setSearchText] = useState('');
  const [open, setOpen] = useState(false); // Estado para controlar o modal
  const [selectedLead, setSelectedLead] = useState(null); // Estado para armazenar o lead selecionado

  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      { accessorKey: 'nome', header: 'Nome', size: 150 },
      { accessorKey: 'criadoEm', header: 'Criado em', size: 98 },
      { accessorKey: 'modificadoEm', header: 'Modificado em', size: 98 },
      { accessorKey: 'origem', header: 'Origem', size: 98 },
      { accessorKey: 'campanha', header: 'Campanha', size: 98 },
      { accessorKey: 'responsavel', header: 'Responsável', size: 98 },
      { accessorKey: 'emails', header: 'Emails', size: 98 },
      { accessorKey: 'telefones', header: 'Telefones', size: 98 },
      { accessorKey: 'status', header: 'Status', size: 98 },
      {
        accessorKey: 'actions',
        header: 'Ações',
        size: 100,
        Cell: ({ row }) => (
          <Box>
            <IconButton onClick={() => handleOpen(row.original)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDelete(row.original.id)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ),
      },
    ],
    []
  );

  const filteredRows = useMemo(() => {
    return rows.filter((row) =>
      Object.keys(row).some((field) =>
        row[field].toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [rows, searchText]);

  const handleOpen = (lead) => {
    setSelectedLead(lead);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedLead(null);
    setOpen(false);
  };

  const handleDelete = (id) => {
    setRows(rows.filter(row => row.id !== id));
  };

  return (
    <Box className="leads-page">
      <Sidebar />
      <Box className="main-content">
        <Container className="container">
          <Typography className="title" variant="h4" gutterBottom>
            Leads
          </Typography>
          <Box className="filters">
          
            <Button variant="contained" color="primary" onClick={() => handleOpen(null)}>
              Novo Lead
            </Button>
            <Button variant="contained" color="secondary">
              Exportar
            </Button>
          </Box>

          <Grid container spacing={2} className="status-cards" justifyContent="center" gap={2}>
          {statusCards.map((card, index) => (
              <Grid item key={index} xs={12} sm={2} className="status-card" style={{ backgroundColor: card.color, width: 20, height: 125 }}>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="50%">
                  <Typography variant="body1" style={{ marginBottom: 2 }}>{card.label}</Typography>
                  <Typography variant="h5">{card.value}</Typography>
                  <IconButton>
                    <VisibilityIcon />
                  </IconButton>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box className="table-container">
          <MaterialReactTable
  columns={columns}
  data={filteredRows}
  initialState={{ density: 'comfortable' }}
  muiTableContainerProps={{ sx: { maxHeight: 'calc(100vh - 50px)' } }}
  muiTableBodyCellProps={{ sx: { p: '8px' } }}
  enableRowSelection
  enableColumnDragging
  enableColumnResizing
  enableStickyHeader
  enableFullScreenToggle={false}
  localization={{
    searchPlaceholder: 'Pesquisar...',
  }}
  muiSearchTextFieldProps={{
    variant: 'outlined',
    placeholder: 'Pesquisar...',
    value: searchText,
    onChange: (e) => setSearchText(e.target.value),
    InputProps: {
      startAdornment: (
        <SearchIcon />
      ),
    },
  }}
/>

          </Box>

          {/* Modal para Novo Lead ou Editar Lead */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box className="modal-box">
              <Typography variant="h6" component="h2">
                {selectedLead ? 'Editar Lead' : 'Novo Lead'}
              </Typography>
              <TextField
                label="Nome"
                variant="outlined"
                fullWidth
                margin="normal"
                value={selectedLead?.nome || ''}
                onChange={(e) => setSelectedLead({ ...selectedLead, nome: e.target.value })}
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Origem"
                    variant="outlined"
                    fullWidth
                    select
                    margin="normal"
                    value={selectedLead?.origem || ''}
                    onChange={(e) => setSelectedLead({ ...selectedLead, origem: e.target.value })}
                  >
                    <MenuItem value="SITE">SITE</MenuItem>
                    <MenuItem value="CAMPANHA">E-MAIL</MenuItem>
                    <MenuItem value="WHATSAPP">WHATSAPP</MenuItem>
                    <MenuItem value="CAMPANHA">CAMPANHA</MenuItem>
                    <MenuItem value="TELEFONE">TELEFONE</MenuItem>
                    <MenuItem value="FACEBOOK">FACEBOOK</MenuItem>
                    <MenuItem value="INSTAGRAM">INSTAGRAM</MenuItem>
                    <MenuItem value="INDICAÇÃO">INDICAÇÃO</MenuItem>
                    <MenuItem value="E-BOOK">E-BOOK</MenuItem>
                    <MenuItem value="CONTATO INTERNACIONAL">CONTATO INTERNACIONAL</MenuItem>
                    {/* Outras opções */}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Status"
                    variant="outlined"
                    fullWidth
                    select
                    margin="normal"
                    value={selectedLead?.status || ''}
                    onChange={(e) => setSelectedLead({ ...selectedLead, status: e.target.value })}
                  >
                    <MenuItem value="Pendente">Pendente</MenuItem>
                    <MenuItem value="Fechado">Proposta</MenuItem>
                    <MenuItem value="Fechado">Negociação</MenuItem>
                    <MenuItem value="Fechado">Apresentação</MenuItem>
                    <MenuItem value="Fechado">Contato</MenuItem>
                    <MenuItem value="Fechado">Reagendado</MenuItem>
                    <MenuItem value="Fechado">Tentativa</MenuItem>
                    <MenuItem value="Fechado">Fechado</MenuItem>
                    <MenuItem value="Fechado">Declinado</MenuItem>
                    {/* Outras opções */}
                  </TextField>
                </Grid>
              </Grid>
              <TextField
                label="Campanha"
                variant="outlined"
                fullWidth
                margin="normal"
                value={selectedLead?.campanha || ''}
                onChange={(e) => setSelectedLead({ ...selectedLead, campanha: e.target.value })}
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Telefone celular (DDI + DDD + Telefone)"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={selectedLead?.telefones || ''}
                    onChange={(e) => setSelectedLead({ ...selectedLead, telefones: e.target.value })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Emails"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={selectedLead?.emails || ''}
                    onChange={(e) => setSelectedLead({ ...selectedLead, emails: e.target.value })}
                  />
                </Grid>
              </Grid>
              <TextField
                label="Telefones"
                variant="outlined"
                fullWidth
                margin="normal"
                value={selectedLead?.telefones || ''}
                onChange={(e) => setSelectedLead({ ...selectedLead, telefones: e.target.value })}
              />
              <TextField
                label="Observações"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                margin="normal"
                value={selectedLead?.observacoes || ''}
                onChange={(e) => setSelectedLead({ ...selectedLead, observacoes: e.target.value })}
              />
              <Button variant="contained" color="primary" fullWidth onClick={handleClose} style={{ marginTop: '16px' }}>
                Salvar dados
              </Button>
            </Box>
          </Modal>
        </Container>
      </Box>
    </Box>
  );
};

export default LeadsPage;
