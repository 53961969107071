import React, { useState } from 'react';
import Sidebar from './Sidebar';
import { Bar } from 'react-chartjs-2';
import {
  Container, Box, Typography, Tabs, Tab, Autocomplete, TextField, FormControl, Select, MenuItem, InputLabel, Grid
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; // Usando AdapterDayjs
import './DashboardPage.css';

const DashboardPage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [period, setPeriod] = useState('este_mes');
  const [chartType, setChartType] = useState('todos');
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [startDate, setStartDate] = useState(null); // Data de início
  const [endDate, setEndDate] = useState(null); // Data de término

  const categories = [
    { group: 'Clientes', value: 'clientes' },
    { group: 'Departamentos', value: 'departamentos' },
    { group: 'Usuários', value: 'usuarios' },
    { group: 'Grupo de Cliente', value: 'grupo_cliente' },
    { group: 'Escritório', value: 'escritorio' },
    { group: 'Tipo de Cliente', value: 'tipo_cliente' },
    { group: 'Tags', value: 'tags' },
    { group: 'Tipo de Serviço', value: 'tipo_servico' },
    { group: 'Grupo de Serviço', value: 'grupo_servico' },
    { group: 'Obrigação', value: 'obrigacao' },
    { group: 'Ordem de Serviço', value: 'ordem_servico' },
    { group: 'Controles', value: 'controles' },
  ];

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handlePeriodChange = (event) => {
    setPeriod(event.target.value);
  };

  const handleChartTypeChange = (event) => {
    setChartType(event.target.value);
  };

  const handleCategoryChange = (event, value) => {
    setSelectedCategory(value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const generateStackedChartData = () => {
    const labels = ['Não Iniciadas', 'Iniciadas', 'Concluídas', 'Suspensas', 'Não Aplicável'];

    const stackedData = {
      labels: labels,
      datasets: [
        {
          label: 'Dentro do Prazo',
          backgroundColor: 'rgba(153, 102, 255, 0.6)', // Roxo
          borderColor: 'rgba(153, 102, 255, 1)',
          borderWidth: 1,
          data: [10, 15, 20, 5, 2],
        },
        {
          label: 'Fora do Prazo Não Sujeita a Multa',
          backgroundColor: 'rgba(255, 159, 64, 0.6)', // Laranja
          borderColor: 'rgba(255, 159, 64, 1)',
          borderWidth: 1,
          data: [5, 10, 7, 3, 1],
        },
        {
          label: 'Fora do Prazo Sujeita a Multa',
          backgroundColor: 'rgba(255, 99, 132, 0.6)', // Vermelho
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
          data: [2, 3, 4, 1, 0],
        },
        {
          label: 'Total',
          backgroundColor: 'rgba(75, 192, 192, 0.6)', // Verde
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
          data: [17, 28, 31, 9, 3],
        },
      ],
    };

    return stackedData;
  };

  const renderLegend = (data) => {
    return (
      <Box className="chart-legend" sx={{ marginLeft: '25px', padding: '10px', backgroundColor: '#f9f9f9', border: '1px solid #ccc', borderRadius: '5px', display: 'flex', flexDirection: 'column' }}>
        {data.datasets.map((dataset, index) => (
          <Typography key={index} variant="body1" sx={{ marginBottom: '10px' }}>
            <strong>{dataset.label}:</strong> {dataset.data.reduce((a, b) => a + b, 0)}
          </Typography>
        ))}
      </Box>
    );
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      datalabels: {
        display: true,
        color: 'black',
        font: {
          weight: 'bold',
        },
        formatter: (value, context) => {
          const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
          return `${value} (${((value / total) * 100).toFixed(0)}%)`;
        },
      },
    },
  };

  const renderCharts = () => {
    const chartData = generateStackedChartData();
    
    return (
      <>
        <Box display="flex" alignItems="center" mb={4}>
          <Typography variant="h6" gutterBottom>
            Resultado Até a Data de Entrega
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <div className="chart-container">
            <Bar data={chartData} options={chartOptions} />
          </div>
          {renderLegend(chartData)}
        </Box>

        <Box display="flex" alignItems="center" mb={4}>
          <Typography variant="h6" gutterBottom>
            Resultado da Data de Entrega Até a Data de Vencimento
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <div className="chart-container">
            <Bar data={chartData} options={chartOptions} />
          </div>
          {renderLegend(chartData)}
        </Box>

        <Box display="flex" alignItems="center" mb={4}>
          <Typography variant="h6" gutterBottom>
            Resultado Após a Data de Vencimento
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <div className="chart-container">
            <Bar data={chartData} options={chartOptions} />
          </div>
          {renderLegend(chartData)}
        </Box>

        <Box display="flex" alignItems="center" mb={4}>
          <Typography variant="h6" gutterBottom>
            Total
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <div className="chart-container">
            <Bar data={chartData} options={chartOptions} />
          </div>
          {renderLegend(chartData)}
        </Box>
      </>
    );
  };

  // Função para renderizar a aba "Dashboard" com gráficos de exemplo
  const renderDashboard = () => (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box className="icon-card">
            <Typography variant="h6">Gráfico de Vendas vs Despesas</Typography>
            <div className="chart-container">
              <Bar data={generateStackedChartData()} options={chartOptions} />
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="icon-card">
            <Typography variant="h6">Indicadores do Mês</Typography>
            <div className="chart-container">
              <Bar data={generateStackedChartData()} options={chartOptions} />
            </div>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );

  // Função para renderizar a aba "Supervisão" com os gráficos de supervisão
  const renderSupervisao = () => (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Supervisão
      </Typography>
      <Box className="filters-container">
        <Grid container spacing={2} alignItems="center" mb={3}>
          <Grid item xs={12} md={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Período</InputLabel>
              <Select value={period} onChange={handlePeriodChange} label="Período">
                <MenuItem value="este_mes">Este Mês</MenuItem>
                <MenuItem value="mes_passado">Mês Passado</MenuItem>
                <MenuItem value="proximo_mes">Próximo Mês</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <DatePicker
              label="Data de Início"
              value={startDate}
              onChange={handleStartDateChange}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <DatePicker
              label="Data de Término"
              value={endDate}
              onChange={handleEndDateChange}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Tipo de Gráfico</InputLabel>
              <Select value={chartType} onChange={handleChartTypeChange} label="Tipo de Gráfico">
                <MenuItem value="todos">Todos</MenuItem>
                <MenuItem value="obrigacao">Obrigação</MenuItem>
                <MenuItem value="ordem_servico">Ordem de Serviço</MenuItem>
                <MenuItem value="controle">Controle</MenuItem>
                <MenuItem value="leads">Leads</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              multiple
              options={categories}
              groupBy={(option) => option.group}
              getOptionLabel={(option) => option.value}
              onChange={handleCategoryChange}
              renderInput={(params) => <TextField {...params} label="Categoria" variant="outlined" fullWidth />}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="supervisao-dashboard-card">
        <Typography variant="h6" gutterBottom>
          Gráfico de Resultados
        </Typography>
        <Box display="flex" alignItems="center">
          <div className="chart-container">
            {renderCharts()}
          </div>
        </Box>
      </Box>
    </Container>
  );

  return (
    <div className="dashboard-page">
      <Sidebar />
      <div className="main-content">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ flexGrow: 1, overflowY: 'auto', maxHeight: '100vh' }}>
            <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth">
              <Tab label="Dashboard" />
              <Tab label="Supervisão" />
            </Tabs>
            {tabIndex === 0 && renderDashboard()}
            {tabIndex === 1 && renderSupervisao()}
          </Box>
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default DashboardPage;
