import React from 'react';
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Avatar,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Fab,
} from '@mui/material';
import { Send, Search, MoreVert, AttachFile, EmojiEmotions, ArrowBack } from '@mui/icons-material';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

function Messenger() {
  // Obtém a data atual no formato brasileiro
  const currentDate = format(new Date(), 'EEEE, d MMMM yyyy HH:mm', { locale: ptBR });

  return (
    <Box display="flex" height="100vh" position="relative" bgcolor="#eaeaea">
      {/* Botão Voltar Flutuante */}
      <Fab
        color="primary"
        aria-label="voltar"
        style={{ position: 'absolute', top: 16, left: 16, zIndex: 1000, backgroundColor: '#075E54' }}
        onClick={() => window.history.back()}
      >
        <ArrowBack />
      </Fab>

      {/* Sidebar */}
      <Box width="30%" bgcolor="#fff" borderRight="1px solid #ddd">
        <AppBar position="static" style={{ backgroundColor: '#075E54' }} elevation={0}>
          <Toolbar>
            <Avatar src="https://randomuser.me/api/portraits/men/32.jpg" />
            <Typography variant="h6" noWrap style={{ marginLeft: '10px' }}>
              Seu Nome
            </Typography>
            <IconButton edge="end" color="inherit">
              <MoreVert />
            </IconButton>
          </Toolbar>
        </AppBar>
        <TextField
          variant="outlined"
          placeholder="Pesquisar ou começar uma nova conversa"
          fullWidth
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            style: { backgroundColor: '#f0f0f0', borderRadius: '20px' },
          }}
          style={{ margin: '10px 16px' }}
        />
        <List style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 150px)' }}>
          {['Sandra Vicente', 'Amparo Arias', 'Floyd Pearson', 'Emma Lemoine', 'Lilja Karimi', 'Ali Akşit', 'Ömür Kunt'].map((name, index) => (
            <ListItem button key={index} style={{ padding: '10px 16px' }}>
              <ListItemAvatar>
                <Avatar alt={name} src={`https://randomuser.me/api/portraits/thumb/${index % 2 === 0 ? 'women' : 'men'}/${index + 1}.jpg`} />
              </ListItemAvatar>
              <ListItemText
                primary={name}
                secondary="Olá! Esta é uma mensagem longa que..."
              />
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Área do Chat */}
      <Box width="70%" display="flex" flexDirection="column" bgcolor="#ece5dd">
        <AppBar position="static" style={{ backgroundColor: '#075E54' }} elevation={0}>
          <Toolbar>
            <Avatar src="https://randomuser.me/api/portraits/women/32.jpg" />
            <Typography variant="h6" noWrap style={{ marginLeft: '10px', flexGrow: 1 }}>
              Título da Conversa
            </Typography>
            <IconButton edge="end" color="inherit">
              <MoreVert />
            </IconButton>
          </Toolbar>
        </AppBar>
        
        {/* Mensagens */}
        <Box flexGrow={1} p={2} bgcolor="#d9dbd5" display="flex" flexDirection="column" style={{ overflowY: 'auto' }}>
          <Typography variant="caption" display="block" textAlign="center" color="textSecondary">
            {currentDate.toUpperCase()}
          </Typography>
          <Box display="flex" flexDirection="column">
            <Paper variant="outlined" style={{ padding: '10px', margin: '10px 0', maxWidth: '60%', alignSelf: 'flex-end', backgroundColor: '#DCF8C6', color: 'black', borderRadius: '7.5px 7.5px 0 7.5px' }}>
              Parece que o texto se ajusta como deveria. Vamos ver como uma resposta fica!
            </Paper>
            <Paper variant="outlined" style={{ padding: '10px', margin: '10px 0', maxWidth: '60%', alignSelf: 'flex-end', backgroundColor: '#DCF8C6', color: 'black', borderRadius: '7.5px 7.5px 0 7.5px' }}>
              Olá! Esta é uma mensagem longa que esperamos que seja envolvida pelo nosso componente de bolha de mensagem! Vamos ver como funciona bem.
            </Paper>
            <Paper variant="outlined" style={{ padding: '10px', margin: '10px 0', maxWidth: '60%', alignSelf: 'flex-start', backgroundColor: 'white', borderRadius: '7.5px 7.5px 7.5px 0' }}>
              Olá! Esta é uma mensagem longa que esperamos que seja envolvida pelo nosso componente de bolha de mensagem! Vamos ver como funciona bem.
            </Paper>
          </Box>
        </Box>
        
        {/* Área de Entrada */}
        <Box p={2} borderTop="1px solid #ddd" display="flex" alignItems="center" bgcolor="#f0f0f0">
          <IconButton>
            <EmojiEmotions />
          </IconButton>
          <IconButton>
            <AttachFile />
          </IconButton>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Digite uma mensagem"
            InputProps={{
              style: { backgroundColor: 'white', borderRadius: '20px' },
            }}
            style={{ marginLeft: '10px', marginRight: '10px' }}
          />
          <IconButton color="primary" style={{ backgroundColor: '#075E54', color: 'white' }}>
            <Send />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default Messenger;
