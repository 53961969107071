import React, { useState } from 'react';
import { 
  Container, 
  Grid, 
  Button, 
  Typography, 
  Card, 
  CardContent, 
  Box, 
  Modal, 
  Tabs, 
  Tab, 
  TextField, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Paper 
} from '@mui/material';
import Sidebar from './Sidebar'; // Certifique-se de que o componente Sidebar está corretamente implementado
import { Bar } from 'react-chartjs-2';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Dados iniciais para o Kanban
const initialTasks = {
  todo: [
    { id: '1', content: 'Contato com o cliente' },
    { id: '2', content: 'Preparar relatórios financeiros' },
    { id: '3', content: 'Análise de fluxo de caixa' },
  ],
  inProgress: [
    { id: '4', content: 'Revisar contratos' },
    { id: '5', content: 'Elaboração de proposta' },
  ],
  done: [
    { id: '6', content: 'Enviar faturas' },
    { id: '7', content: 'Reunião de acompanhamento' },
  ]
};

const CRMPanel = () => {
  const [openModal, setOpenModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [tasks, setTasks] = useState(initialTasks);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setTabIndex(0); // Reinicia as abas ao fechar o modal
  };
  const handleTabChange = (event, newValue) => setTabIndex(newValue);
  const handleNextTab = () => setTabIndex((prevIndex) => prevIndex + 1);
  const handlePrevTab = () => setTabIndex((prevIndex) => prevIndex - 1);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    const sourceColumn = tasks[source.droppableId];
    const destColumn = tasks[destination.droppableId];
    const sourceItems = Array.from(sourceColumn);
    const destItems = Array.from(destColumn);
    const [removed] = sourceItems.splice(source.index, 1);

    if (source.droppableId === destination.droppableId) {
      sourceItems.splice(destination.index, 0, removed);
      setTasks({
        ...tasks,
        [source.droppableId]: sourceItems
      });
    } else {
      destItems.splice(destination.index, 0, removed);
      setTasks({
        ...tasks,
        [source.droppableId]: sourceItems,
        [destination.droppableId]: destItems
      });
    }
  };

  // Dados simulados para o gráfico de "Receita de Negócios Futuros"
  const data = {
    labels: ['Abr', 'Mai', 'Jun', 'Jul', 'Ago'],
    datasets: [
      {
        label: 'Ganho',
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.6)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        data: [200000, 220000, 180000, 260000, 240000],
      },
      {
        label: 'Perdido',
        backgroundColor: 'rgba(255,99,132,0.4)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.6)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: [-50000, -60000, -80000, -100000, -70000],
      }
    ]
  };

  return (
    <Box sx={{ display: 'flex', bgcolor: '#f5f5f5', minHeight: '100vh' }}>
      <Sidebar />
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Container sx={{ mt: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              {/* Receita de Negócios Futuros */}
              <Card>
                <CardContent>
                  <Typography variant="h6">Receita de Negócios Futuros</Typography>
                  <Bar data={data} />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              {/* Tarefas Pendentes */}
              <Card>
                <CardContent>
                  <Typography variant="h6">Tarefas Pendentes</Typography>
                  <Button variant="contained" onClick={handleOpenModal}>Adicionar Tarefa</Button>
                  {/* Lista de tarefas */}
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              {/* Contatos Importantes */}
              <Card>
                <CardContent>
                  <Typography variant="h6">Contatos Importantes</Typography>
                  {/* Lista de contatos */}
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={8}>
              {/* Últimas Atividades */}
              <Card>
                <CardContent>
                  <Typography variant="h6">Últimas Atividades</Typography>
                  {/* Atividades recentes */}
                </CardContent>
              </Card>
            </Grid>

            {/* Kanban Board */}
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Kanban de Tarefas
              </Typography>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Grid container spacing={3}>
                  {Object.keys(tasks).map((columnId) => {
                    const column = tasks[columnId];
                    return (
                      <Grid item xs={12} md={4} key={columnId}>
                        <Typography variant="h6" gutterBottom>
                          {columnId === 'todo' && 'A Fazer'}
                          {columnId === 'inProgress' && 'Em Progresso'}
                          {columnId === 'done' && 'Concluído'}
                        </Typography>
                        <Droppable droppableId={columnId}>
                          {(provided) => (
                            <Paper 
                              {...provided.droppableProps} 
                              ref={provided.innerRef}
                              sx={{ 
                                minHeight: 400, 
                                padding: 2, 
                                backgroundColor: '#f4f6f8',
                                borderRadius: '8px',
                                boxShadow: '0px 2px 10px rgba(0,0,0,0.1)',
                              }}
                            >
                              {column.map((task, index) => (
                                <Draggable key={task.id} draggableId={task.id} index={index}>
                                  {(provided) => (
                                    <Box
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      sx={{
                                        marginBottom: 2,
                                        padding: 2,
                                        backgroundColor: 'white',
                                        borderRadius: '8px',
                                        boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
                                        '&:hover': {
                                          backgroundColor: '#f0f0f0',
                                        }
                                      }}
                                    >
                                      {task.content}
                                    </Box>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </Paper>
                          )}
                        </Droppable>
                      </Grid>
                    );
                  })}
                </Grid>
              </DragDropContext>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Modal para adicionar ou editar tarefa/contato */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="modal-title" variant="h6" component="h2">
            Adicionar Nova Tarefa
          </Typography>
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="form tabs">
            <Tab label="Informações do Cliente" />
            <Tab label="Serviços Contratados" />
            <Tab label="Histórico de Comunicação" />
            <Tab label="Faturamento e Pagamentos" />
            <Tab label="Documentos" />
            <Tab label="Tarefas e Prazos" />
            <Tab label="Observações" />
          </Tabs>
          <Box sx={{ mt: 2 }}>
            {/* Conteúdo de cada aba */}
            {tabIndex === 0 && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Nome Completo" variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Razão Social" variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="CNPJ/CPF" variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Email" variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Telefone" variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Responsável pela Conta" variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Endereço Completo" variant="outlined" />
                </Grid>
              </Grid>
            )}
            {tabIndex === 1 && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Tipo de Serviço</InputLabel>
                    <Select label="Tipo de Serviço">
                      <MenuItem value="Contábil">Contábil</MenuItem>
                      <MenuItem value="Fiscal">Fiscal</MenuItem>
                      <MenuItem value="Departamento Pessoal">Departamento Pessoal</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Data de Início" variant="outlined" type="date" InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Status do Serviço</InputLabel>
                    <Select label="Status do Serviço">
                      <MenuItem value="Ativo">Ativo</MenuItem>
                      <MenuItem value="Pendente">Pendente</MenuItem>
                      <MenuItem value="Suspenso">Suspenso</MenuItem>
                      <MenuItem value="Cancelado">Cancelado</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Valor Contratado" variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Ciclo de Faturamento</InputLabel>
                    <Select label="Ciclo de Faturamento">
                      <MenuItem value="Mensal">Mensal</MenuItem>
                      <MenuItem value="Trimestral">Trimestral</MenuItem>
                      <MenuItem value="Anual">Anual</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {tabIndex === 2 && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField fullWidth label="Data da Interação" variant="outlined" type="date" InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Tipo de Interação</InputLabel>
                    <Select label="Tipo de Interação">
                      <MenuItem value="Email">Email</MenuItem>
                      <MenuItem value="Telefone">Telefone</MenuItem>
                      <MenuItem value="Reunião">Reunião</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Descrição da Interação" variant="outlined" multiline rows={3} />
                </Grid>
              </Grid>
            )}
            {tabIndex === 3 && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField fullWidth label="Data da Fatura" variant="outlined" type="date" InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField fullWidth label="Valor da Fatura" variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Status do Pagamento</InputLabel>
                    <Select label="Status do Pagamento">
                      <MenuItem value="Pago">Pago</MenuItem>
                      <MenuItem value="Pendente">Pendente</MenuItem>
                      <MenuItem value="Atrasado">Atrasado</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Meio de Pagamento</InputLabel>
                    <Select label="Meio de Pagamento">
                      <MenuItem value="Boleto">Boleto</MenuItem>
                      <MenuItem value="Transferência">Transferência</MenuItem>
                      <MenuItem value="Cartão">Cartão</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {tabIndex === 4 && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Tipo de Documento</InputLabel>
                    <Select label="Tipo de Documento">
                      <MenuItem value="Contrato">Contrato</MenuItem>
                      <MenuItem value="Nota Fiscal">Nota Fiscal</MenuItem>
                      <MenuItem value="Relatório">Relatório</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Data de Emissão" variant="outlined" type="date" InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" component="label">
                    Upload de Documento
                    <input type="file" hidden />
                  </Button>
                </Grid>
              </Grid>
            )}
            {tabIndex === 5 && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <TextField fullWidth label="Descrição da Tarefa" variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField fullWidth label="Data de Vencimento" variant="outlined" type="date" InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Prioridade</InputLabel>
                    <Select label="Prioridade">
                      <MenuItem value="Alta">Alta</MenuItem>
                      <MenuItem value="Média">Média</MenuItem>
                      <MenuItem value="Baixa">Baixa</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Status da Tarefa</InputLabel>
                    <Select label="Status da Tarefa">
                      <MenuItem value="Pendente">Pendente</MenuItem>
                      <MenuItem value="Em Progresso">Em Progresso</MenuItem>
                      <MenuItem value="Concluída">Concluída</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField fullWidth label="Responsável" variant="outlined" />
                </Grid>
              </Grid>
            )}
            {tabIndex === 6 && (
              <TextField
                fullWidth
                label="Observações"
                variant="outlined"
                multiline
                rows={4}
              />
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            {tabIndex > 0 && (
              <Button variant="contained" onClick={handlePrevTab}>
                Voltar
              </Button>
            )}
            {tabIndex < 6 && (
              <Button variant="contained" onClick={handleNextTab}>
                Próximo
              </Button>
            )}
            {tabIndex === 6 && (
              <Button variant="contained" color="success" onClick={handleCloseModal}>
                Salvar
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default CRMPanel;
