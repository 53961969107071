import React, { useMemo, useState } from 'react';
import Sidebar from './Sidebar';
import { FaFilter } from 'react-icons/fa'; // Adicione esta linha
import { Button, TextField, InputAdornment, Select, MenuItem, FormControl, InputLabel, Container, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import './ObrigacoesPage.css';


const obrigacoesData = [
  { id: 1, nome: '13º SALÁRIO - INTEGRAL', depto: 'Depto Pessoal', empresas: 0, data: '30/Nov', multa: 'Não', robo: 'Não', compet: 'Mês anterior', lembrar: '15 dias antes' },
  { id: 2, nome: '1ª PARCELA 13º SALÁRIO', depto: 'Depto Pessoal', empresas: 51, data: '30/Nov', multa: 'Não', robo: 'Não', compet: 'Mesmo mês', lembrar: '5 dias antes' },
  { id: 3, nome: '2ª PARCELA 13º SALÁRIO', depto: 'Depto Pessoal', empresas: 51, data: '20/Dez', multa: 'Não', robo: 'Não', compet: 'Mesmo mês', lembrar: '5 dias antes' },
  { id: 4, nome: 'PIS - Mensal', depto: 'Fiscal', empresas: 100, data: '25/Dez', multa: 'Sim', robo: 'Não', compet: 'Mês anterior', lembrar: '10 dias antes' },
  { id: 5, nome: 'COFINS - Mensal', depto: 'Fiscal', empresas: 95, data: '25/Dez', multa: 'Sim', robo: 'Sim', compet: 'Mês anterior', lembrar: '10 dias antes' },
  { id: 6, nome: 'IRPJ - Trimestral', depto: 'Fiscal', empresas: 40, data: '31/Mar', multa: 'Sim', robo: 'Não', compet: 'Trimestre anterior', lembrar: '15 dias antes' },
  { id: 7, nome: 'CSLL - Trimestral', depto: 'Fiscal', empresas: 40, data: '31/Mar', multa: 'Sim', robo: 'Não', compet: 'Trimestre anterior', lembrar: '15 dias antes' },
  { id: 8, nome: 'ISS - Mensal', depto: 'Financeiro', empresas: 60, data: '10/Jan', multa: 'Sim', robo: 'Sim', compet: 'Mês anterior', lembrar: '5 dias antes' },
  { id: 9, nome: 'ICMS - Mensal', depto: 'Financeiro', empresas: 70, data: '20/Jan', multa: 'Sim', robo: 'Sim', compet: 'Mês anterior', lembrar: '5 dias antes' },
  { id: 10, nome: 'FGTS - Mensal', depto: 'Depto Pessoal', empresas: 80, data: '07/Fev', multa: 'Sim', robo: 'Não', compet: 'Mês anterior', lembrar: '10 dias antes' },
];

const ObrigacoesPage = () => {
  const [filter, setFilter] = useState('');
  const [departmentFilter, setDepartmentFilter] = useState('');
  const navigate = useNavigate();

  const handleNewObrigacao = () => {
    navigate('/nova-obrigacao');
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleDepartmentFilterChange = (event) => {
    setDepartmentFilter(event.target.value);
  };

  const filteredData = useMemo(() => {
    return obrigacoesData.filter(obrigacao =>
      obrigacao.nome.toLowerCase().includes(filter.toLowerCase()) &&
      (departmentFilter === '' || obrigacao.depto === departmentFilter)
    );
  }, [filter, departmentFilter]);

  const columns = useMemo(() => [
    { accessorKey: 'nome', header: 'Obrigação' },
    { accessorKey: 'depto', header: 'Departamento' },
    { accessorKey: 'empresas', header: 'Qtde empresas' },
    { accessorKey: 'data', header: 'Datas para entrega (DU = Dia Útil)' },
    { accessorKey: 'multa', header: 'Multa?' },
    { accessorKey: 'robo', header: 'Robô?' },
    { accessorKey: 'compet', header: 'Compet.' },
    { accessorKey: 'lembrar', header: 'Lembrar' },
  ], []);

  return (
    <div className="obrigacoes-page">
      <Sidebar />
      <div className="main-content">
        <Container className="container">
          <Grid container spacing={3} alignItems="center" className="header-container">
            <Grid item xs={12} md={8}>
              <Typography variant="h6" className="page-title">
                Obrigações
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} className="button-container">
              <Button variant="contained" className="new-obligation-button" onClick={handleNewObrigacao}>
                Nova Obrigação
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems="center" className="filter-container">
         
         
          </Grid>
          <div className="table-container">
            <MaterialReactTable
              columns={columns}
              data={filteredData}
              localization={{
                toolbar: {
                  searchPlaceholder: 'Pesquisar...',
                },
                pagination: {
                  labelRowsPerPage: 'Linhas por página',
                  labelDisplayedRows: '{from}-{to} de {count}',
                  labelRowsSelect: 'linhas',
                },
                header: {
                  actions: 'Ações',
                },
                noData: 'Nenhum dado encontrado',
              }}
              enableRowSelection
              enablePagination
              enableColumnOrdering
              muiTablePaginationProps={{
                rowsPerPageOptions: [10, 10, 20],
                labelRowsPerPage: 'Linhas por página',
              }}
              initialState={{
                pagination: {
                  pageSize: 10,
                },
              }}
              muiTableContainerProps={{
                sx: {
                  height: 'calc(100vh - 200px)', // Ajuste conforme necessário para a altura desejada
                  maxHeight: '75%', // Garantir que o conteúdo não ultrapasse o contêiner
                },
              }}
              muiTablePaperProps={{
                sx: {
                  width: '100%', // Para ocupar 100% da largura disponível
                  height: '100%', // Para ocupar 100% da altura disponível
                },
              }}
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ObrigacoesPage;
