import React, { useMemo, useState } from 'react';
import { Box, Button, Typography, IconButton, Tooltip, Container, Modal, TextField, MenuItem } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import FilterListIcon from '@mui/icons-material/FilterList';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer } from 'recharts';


const initialTransactions = [
  { id: 1, descricao: 'Venda de Produto A', tipo: 'Entrada', valor: 2000, data: '01/09/2024' },
  { id: 2, descricao: 'Pagamento Fornecedor B', tipo: 'Saída', valor: 1500, data: '05/09/2024' },
  { id: 3, descricao: 'Serviço Prestado C', tipo: 'Entrada', valor: 3000, data: '10/09/2024' },
  { id: 4, descricao: 'Compra de Materiais D', tipo: 'Saída', valor: 1000, data: '15/09/2024' },
];

const FluxoCaixaPage = () => {
  const [transactions, setTransactions] = useState(initialTransactions);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTransaction, setNewTransaction] = useState({
    descricao: '',
    tipo: '',
    valor: '',
    data: '',
  });

  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      { accessorKey: 'descricao', header: 'Descrição', size: 250 },
      { accessorKey: 'tipo', header: 'Tipo', size: 150 },
      { accessorKey: 'valor', header: 'Valor', size: 150, Cell: ({ cell }) => `R$ ${cell.getValue().toFixed(2)}` },
      { accessorKey: 'data', header: 'Data', size: 150 },
    ],
    []
  );

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewTransaction((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const id = transactions.length + 1;
    const novaTransacao = { id, ...newTransaction, valor: parseFloat(newTransaction.valor) };
    setTransactions((prev) => [...prev, novaTransacao]);
    handleCloseModal();
  };

  // Preparando os dados para o gráfico
  const dataForChart = useMemo(() => {
    const groupedData = transactions.reduce((acc, trans) => {
      const existing = acc.find(item => item.data === trans.data);
      if (existing) {
        if (trans.tipo === 'Entrada') {
          existing.entrada += trans.valor;
        } else {
          existing.saida += trans.valor;
        }
      } else {
        acc.push({
          data: trans.data,
          entrada: trans.tipo === 'Entrada' ? trans.valor : 0,
          saida: trans.tipo === 'Saída' ? trans.valor : 0,
        });
      }
      return acc;
    }, []);
    return groupedData;
  }, [transactions]);

  return (
    <Box className="fluxo-caixa-container" sx={{ display: 'flex' }}>
      <Sidebar />
      <Box className="fluxo-caixa-content" sx={{ flexGrow: 1, p: 3 }}>
        <Container>
          <Typography variant="h4" gutterBottom className="fluxo-caixa-title" sx={{ fontWeight: 'bold', mb: 4 }}>
            Fluxo de Caixa
          </Typography>
          
          {/* Gráfico de Linhas */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Entradas e Saídas por Data
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={dataForChart}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="data" />
                <YAxis />
                <RechartsTooltip />
                <Legend />
                <Line type="monotone" dataKey="entrada" stroke="#82ca9d" name="Entrada" />
                <Line type="monotone" dataKey="saida" stroke="#ff6b6b" name="Saída" />
              </LineChart>
            </ResponsiveContainer>
          </Box>

          <Box className="fluxo-caixa-actions" sx={{ mb: 4, display: 'flex', justifyContent: 'space-between' }}>
            <Tooltip title="Filtros">
              <IconButton>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
            >
              Nova Transação
            </Button>
          </Box>

          <Box className="fluxo-caixa-table" sx={{ mb: 4 }}>
            <MaterialReactTable
              columns={columns}
              data={transactions}
              initialState={{ density: 'comfortable' }}
              muiTableContainerProps={{ sx: { maxHeight: 'calc(100vh - 200px)' } }}
              muiTableBodyCellProps={{ sx: { p: '8px' } }}
              enableRowSelection
              enableColumnDragging
              enableColumnResizing
              enableStickyHeader
              enableFullScreenToggle={false}
              localization={{
                searchPlaceholder: 'Pesquisar...',
              }}
              muiSearchTextFieldProps={{
                variant: 'outlined',
                placeholder: 'Pesquisar Transações',
              }}
            />
          </Box>
        </Container>
      </Box>

      {/* Modal para adicionar nova transação */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          borderRadius: '8px',
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" gutterBottom>
            Adicionar Nova Transação
          </Typography>
          <TextField
            label="Descrição"
            name="descricao"
            value={newTransaction.descricao}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Tipo"
            name="tipo"
            select
            value={newTransaction.tipo}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            <MenuItem value="Entrada">Entrada</MenuItem>
            <MenuItem value="Saída">Saída</MenuItem>
          </TextField>
          <TextField
            label="Valor"
            name="valor"
            type="number"
            value={newTransaction.valor}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Data"
            name="data"
            type="date"
            value={newTransaction.data}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Box sx={{ mt: 2, textAlign: 'right' }}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Adicionar
            </Button>
            <Button variant="text" color="secondary" onClick={handleCloseModal} sx={{ ml: 2 }}>
              Cancelar
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default FluxoCaixaPage;
