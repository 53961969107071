import React, { useState } from 'react';
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Card,
  CardContent,
  IconButton,
  Modal,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  AppBar,
  Toolbar,
  Divider,
} from '@mui/material';
import {
  Folder,
  Search,
  PictureAsPdf,
  InsertDriveFile,
  UploadFile,
  Add,
  Description,
  AssignmentTurnedIn,
  ArrowBack,
  Close,
  Menu as MenuIcon,
  ChevronLeft,
} from '@mui/icons-material';

const SidebarCustom = ({ handleOpenModal, isCollapsed, toggleCollapse }) => (
  <Drawer
    variant="permanent"
    anchor="left"
    sx={{
      width: isCollapsed ? 70 : 240,
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        width: isCollapsed ? 70 : 240,
        boxSizing: 'border-box',
        transition: 'width 0.3s',
      },
    }}
  >
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <IconButton onClick={toggleCollapse} sx={{ m: 1 }}>
        {isCollapsed ? <MenuIcon /> : <ChevronLeft />}
      </IconButton>
      <List>
        <ListItem button onClick={() => handleOpenModal('cadastro')}>
          <ListItemIcon>
            <Description />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="Cadastro Documento" />}
        </ListItem>
        <ListItem button onClick={() => handleOpenModal('insercao')}>
          <ListItemIcon>
            <Add />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="Inserção de Documentos" />}
        </ListItem>
      </List>
      <Divider sx={{ mt: 'auto' }} />
      <Button
        startIcon={<ArrowBack />}
        variant="contained"
        color="primary"
        href="http://localhost:3001/dashboard"
        fullWidth
        sx={{ mt: 2, mb: 2 }}
      >
        {!isCollapsed && 'Voltar ao Sistema'}
      </Button>
    </Box>
  </Drawer>
);

const DocumentManager = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);

  const handleOpenModal = (type) => {
    setModalType(type);
    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);
  const toggleSidebarCollapse = () => setSidebarCollapsed(!isSidebarCollapsed);

  const files = [
    { id: 1, name: 'Documento.pdf', extension: 'pdf' },
    { id: 2, name: 'Documento.doc', extension: 'doc' },
    { id: 3, name: 'Documento.txt', extension: 'txt' },
    { id: 4, name: 'Documento.xls', extension: 'xls' },
  ];

  const renderModalContent = () => {
    let title = '';
    switch (modalType) {
      case 'cadastro':
        title = 'Cadastro de Documento';
        break;
      case 'insercao':
        title = 'Inserção de Documentos';
        break;
      case 'camposObrigatorios':
        title = 'Campos Obrigatórios';
        break;
      default:
        title = '';
    }

    return (
      <>
        <AppBar position="static" color="default" sx={{ mb: 2 }}>
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {title}
            </Typography>
            <IconButton edge="end" color="inherit" onClick={handleCloseModal}>
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ maxHeight: '75vh', overflowY: 'auto', px: 2 }}>
          <Grid container spacing={2}>
            {modalType === 'cadastro' && (
              <>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Status do Documento</InputLabel>
                    <Select label="Status do Documento">
                      <MenuItem value="Ativo">Ativo</MenuItem>
                      <MenuItem value="Inativo">Inativo</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Código do Documento" variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Classificação do Documento" variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Nome do Documento" variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Mini Nome do Documento" variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Departamento</InputLabel>
                    <Select label="Departamento">
                      <MenuItem value="Fiscal">Fiscal</MenuItem>
                      <MenuItem value="Contábil">Contábil</MenuItem>
                      <MenuItem value="RH">RH</MenuItem>
                      <MenuItem value="TI">TI</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Palavra Chave do Documento" variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Tipo do Documento" variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Grupo do Documento" variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Extensão do Documento" variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Pasta" variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Prazo em Dias Expurgo" variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Modelo do Documento" variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Observações" variant="outlined" multiline rows={3} />
                </Grid>
              </>
            )}
            {modalType === 'insercao' && (
              <>
                <Grid item xs={12}>
                  <TextField fullWidth label="Destino do Documento" variant="outlined" required />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Palavra Chave" variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Código do Documento" variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Classificação" variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Nome do Documento" variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Mini Nome" variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Status do Documento</InputLabel>
                    <Select label="Status do Documento">
                      <MenuItem value="Ativo">Ativo</MenuItem>
                      <MenuItem value="Inativo">Inativo</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Departamento</InputLabel>
                    <Select label="Departamento">
                      <MenuItem value="Fiscal">Fiscal</MenuItem>
                      <MenuItem value="Contábil">Contábil</MenuItem>
                      <MenuItem value="RH">RH</MenuItem>
                      <MenuItem value="TI">TI</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Usuário" variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Tipo do Documento" variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Grupo do Documento" variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Extensão do Documento" variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Data de Cadastrado com Data Retroativa"
                    variant="outlined"
                    required
                    type="date"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Data de Competência"
                    variant="outlined"
                    required
                    type="date"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Vencimento"
                    variant="outlined"
                    required
                    type="date"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Prazo para Expurgo" variant="outlined" required />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    sx={{ mt: 2 }}
                    startIcon={<UploadFile />}
                  >
                    Selecione o arquivo
                    <input type="file" hidden />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Observações" variant="outlined" multiline rows={3} />
                </Grid>
              </>
            )}
            {modalType === 'camposObrigatorios' && (
              <>
                <Grid item xs={12}>
                  <TextField fullWidth label="Destino do Documento" variant="outlined" required />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Palavra Chave" variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Código do Documento" variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Classificação" variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Nome do Documento" variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Mini Nome" variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Status do Documento</InputLabel>
                    <Select label="Status do Documento">
                      <MenuItem value="Ativo">Ativo</MenuItem>
                      <MenuItem value="Inativo">Inativo</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Departamento</InputLabel>
                    <Select label="Departamento">
                      <MenuItem value="Fiscal">Fiscal</MenuItem>
                      <MenuItem value="Contábil">Contábil</MenuItem>
                      <MenuItem value="RH">RH</MenuItem>
                      <MenuItem value="TI">TI</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Usuário" variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Tipo do Documento" variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Grupo do Documento" variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Extensão do Documento" variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Data de Cadastrado com Data Retroativa"
                    variant="outlined"
                    required
                    type="date"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Data de Competência"
                    variant="outlined"
                    required
                    type="date"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Vencimento"
                    variant="outlined"
                    required
                    type="date"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Prazo para Expurgo" variant="outlined" required />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    sx={{ mt: 2 }}
                    startIcon={<UploadFile />}
                  >
                    Selecione o arquivo
                    <input type="file" hidden />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Observações" variant="outlined" multiline rows={3} />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </>
    );
  };

  return (
    <Box sx={{ display: 'flex', bgcolor: '#f5f5f5', minHeight: '100vh' }}>
      <SidebarCustom
        handleOpenModal={handleOpenModal}
        isCollapsed={isSidebarCollapsed}
        toggleCollapse={toggleSidebarCollapse}
      />
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Container sx={{ mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            Gerenciamento de Documentos
          </Typography>

          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Palavra Chave" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Departamento</InputLabel>
                <Select label="Departamento">
                  <MenuItem value="Fiscal">Fiscal</MenuItem>
                  <MenuItem value="Contábil">Contábil</MenuItem>
                  <MenuItem value="RH">RH</MenuItem>
                  <MenuItem value="TI">TI</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Extensão</InputLabel>
                <Select label="Extensão">
                  <MenuItem value="pdf">PDF</MenuItem>
                  <MenuItem value="doc">DOC</MenuItem>
                  <MenuItem value="xls">XLS</MenuItem>
                  <MenuItem value="txt">TXT</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Card>
            <CardContent>
              <Typography variant="h6">Documentos Disponíveis</Typography>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {files.map((file) => (
                  <Grid item xs={12} sm={3} key={file.id} sx={{ textAlign: 'center' }}>
                    <IconButton color="secondary">
                      {file.extension === 'pdf' && <PictureAsPdf sx={{ fontSize: 40 }} />}
                      {file.extension === 'doc' && <InsertDriveFile sx={{ fontSize: 40 }} />}
                      {file.extension === 'txt' && <InsertDriveFile sx={{ fontSize: 40 }} />}
                      {file.extension === 'xls' && <InsertDriveFile sx={{ fontSize: 40 }} />}
                    </IconButton>
                    <Typography display="block">{file.name}</Typography>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </Box>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxHeight: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            overflowY: 'auto',
            p: 2,
          }}
        >
          {renderModalContent()}
          <Box sx={{ p: 2 }}>
            <Button variant="contained" color="success" fullWidth onClick={handleCloseModal}>
              Salvar
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default DocumentManager;
