import React, { useMemo, useState } from 'react';
import Sidebar from './Sidebar';
import { Box, Button, Typography, IconButton, Tooltip, Container } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useNavigate } from 'react-router-dom';
import './OrdemDeServicoPage.css';

const initialRows = [
  { id: 1, numero: 'OS-001', cliente: 'Empresa A', data: '15/08/2024', status: 'Em andamento' },
  { id: 2, numero: 'OS-002', cliente: 'Empresa B', data: '20/08/2024', status: 'Concluída' },
  { id: 3, numero: 'OS-003', cliente: 'Empresa C', data: '25/08/2024', status: 'Pendente' },
  // Adicione mais ordens de serviço de exemplo aqui
];

const OrdemDeServicoPage = () => {
  const [rows, setRows] = useState(initialRows);
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      { accessorKey: 'numero', header: 'Número da O.S', size: 150 },
      { accessorKey: 'cliente', header: 'Cliente', size: 200 },
      { accessorKey: 'data', header: 'Data', size: 150 },
      { accessorKey: 'status', header: 'Status', size: 150 },
    ],
    []
  );

  return (
    <Box className="ordem-servico-container">
      <Sidebar />
      <Box className="ordem-servico-content">
        <Container>
          <Typography variant="h4" gutterBottom className="ordem-servico-title">
            Relação de Ordens de Serviço
          </Typography>
          <Box className="ordem-servico-actions">
            <Tooltip title="Filtros">
              <IconButton>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/nova-os')}
            >
              Nova O.S.
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/WorkflowPage')}
            >
             Workflow
            </Button>
          </Box>
          <Box className="ordem-servico-table">
            <MaterialReactTable
              columns={columns}
              data={rows}
              initialState={{ density: 'comfortable' }}
              muiTableContainerProps={{ sx: { maxHeight: 'calc(100vh - 200px)' } }}
              muiTableBodyCellProps={{ sx: { p: '8px' } }}
              enableRowSelection
              enableColumnDragging
              enableColumnResizing
              enableStickyHeader
              enableFullScreenToggle={false}
              localization={{
                searchPlaceholder: 'Pesquisar...',
              }}
              muiSearchTextFieldProps={{
                variant: 'outlined',
                placeholder: 'Pesquisar O.S.',
              }}
            />
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default OrdemDeServicoPage;
