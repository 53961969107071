import React, { useState, useCallback } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  Controls,
  Background,
} from 'reactflow';
import 'reactflow/dist/style.css';
import {
  Box,
  Button,
  Typography,
  Container,
  Modal,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  IconButton,
} from '@mui/material';
import Sidebar from './Sidebar';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';

const initialNodes = [
  { id: '1', type: 'input', data: { label: 'Início do Workflow' }, position: { x: 250, y: 5 } },
];

const initialEdges = [];

const WorkflowPage = () => {
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isListView, setIsListView] = useState(false);
  const [editNodeId, setEditNodeId] = useState(null);
  const [newStep, setNewStep] = useState({
    codigo: '',
    classificacao: '',
    descricao: '',
    oQueFazer: '',
    departamento: '',
    prazoVencimento: '',
    prazoInicio: '',
    prazoEntrega: '',
    responsavel: '',
    acaoSim: false,
    acaoNao: false,
  });

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );

  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const handleOpenModal = (nodeId = null) => {
    if (nodeId) {
      const node = nodes.find((n) => n.id === nodeId);
      if (node) {
        const label = node?.data?.label;
        setNewStep({
          codigo: typeof label?.props?.children[1]?.props?.children === 'string' ? label?.props?.children[1]?.props?.children.split(': ')[1] : '',
          classificacao: typeof label?.props?.children[2]?.props?.children === 'string' ? label?.props?.children[2]?.props?.children.split(': ')[1] : '',
          descricao: typeof label?.props?.children[0]?.props?.children === 'string' ? label?.props?.children[0]?.props?.children : '',
          oQueFazer: typeof label?.props?.children[3]?.props?.children === 'string' ? label?.props?.children[3]?.props?.children.split(': ')[1] : '',
          departamento: typeof label?.props?.children[4]?.props?.children === 'string' ? label?.props?.children[4]?.props?.children.split(': ')[1] : '',
          prazoVencimento: typeof label?.props?.children[5]?.props?.children === 'string' ? label?.props?.children[5]?.props?.children.split(': ')[1] : '',
          prazoInicio: typeof label?.props?.children[6]?.props?.children === 'string' ? label?.props?.children[6]?.props?.children.split(': ')[1] : '',
          prazoEntrega: typeof label?.props?.children[7]?.props?.children === 'string' ? label?.props?.children[7]?.props?.children.split(': ')[1] : '',
          responsavel: typeof label?.props?.children[8]?.props?.children === 'string' ? label?.props?.children[8]?.props?.children.split(': ')[1] : '',
          acaoSim: label?.props?.children[9]?.props?.children?.includes('Adicionar/Remover') || false,
          acaoNao: label?.props?.children[9]?.props?.children?.includes('Retornar no Passo') || false,
        });
        setEditNodeId(nodeId);
      }
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditNodeId(null);
    setNewStep({
      codigo: '',
      classificacao: '',
      descricao: '',
      oQueFazer: '',
      departamento: '',
      prazoVencimento: '',
      prazoInicio: '',
      prazoEntrega: '',
      responsavel: '',
      acaoSim: false,
      acaoNao: false,
    });
  };

  const handleAddOrUpdateStep = () => {
    const newNode = {
      id: editNodeId || `${nodes.length + 1}`,
      type: 'default',
      position: editNodeId
        ? nodes.find((n) => n.id === editNodeId).position
        : { x: 250 + nodes.length * 150, y: 150 },
      data: {
        label: (
          <Box sx={{ textAlign: 'left' }}>
            <Typography variant="h6">{newStep.descricao || 'Sem Descrição'}</Typography>
            <Typography variant="body2">Código: {newStep.codigo || 'Sem Código'}</Typography>
            <Typography variant="body2">Classificação: {newStep.classificacao || 'Sem Classificação'}</Typography>
            <Typography variant="body2">O que fazer: {newStep.oQueFazer || 'Sem Instrução'}</Typography>
            <Typography variant="body2">Departamento: {newStep.departamento || 'Sem Departamento'}</Typography>
            <Typography variant="body2">Prazo de Vencimento: {newStep.prazoVencimento || '0'} dias</Typography>
            <Typography variant="body2">Prazo para Início: {newStep.prazoInicio || '0'} dias</Typography>
            <Typography variant="body2">Prazo para Entrega: {newStep.prazoEntrega || '0'} dias</Typography>
            <Typography variant="body2">Responsável: {newStep.responsavel || 'Não Definido'}</Typography>
            <Typography variant="body2">
              Ações: Sim: {newStep.acaoSim ? 'Adicionar/Remover' : 'N/A'}, Não: {newStep.acaoNao ? 'Retornar no Passo' : 'N/A'}
            </Typography>
            <IconButton onClick={() => handleOpenModal(editNodeId || newNode.id)}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Box>
        ),
      },
    };

    setNodes((nds) => {
      if (editNodeId) {
        return nds.map((n) => (n.id === editNodeId ? newNode : n));
      }
      return [...nds, newNode];
    });

    handleCloseModal();
  };

  const toggleListView = () => {
    setIsListView(!isListView);
  };

  return (
    <Box className="workflow-container" sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
      <Sidebar />
      <Box className="workflow-content" sx={{ flexGrow: 1, overflowY: 'auto' }}>
        <Container>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h4" gutterBottom className="workflow-title">
              Fluxo de Ordem de Serviço
            </Typography>
            <IconButton onClick={toggleListView}>
              <ListIcon fontSize="large" />
            </IconButton>
          </Box>
          <Button variant="contained" color="primary" onClick={() => handleOpenModal()} fullWidth>
            Adicionar Novo Passo
          </Button>

          <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ padding: 4, backgroundColor: 'white', width: '90%', maxWidth: 600, borderRadius: 4, overflowY: 'auto', maxHeight: '90%' }}>
              <Typography variant="h6" gutterBottom>
                {editNodeId ? 'Editar Passo' : 'Adicionar Novo Passo'}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Código"
                    variant="outlined"
                    value={newStep.codigo}
                    onChange={(e) => setNewStep({ ...newStep, codigo: e.target.value })}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Classificação"
                    variant="outlined"
                    value={newStep.classificacao}
                    onChange={(e) => setNewStep({ ...newStep, classificacao: e.target.value })}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Descrição do Passo"
                    variant="outlined"
                    value={newStep.descricao}
                    onChange={(e) => setNewStep({ ...newStep, descricao: e.target.value })}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="O que Fazer?"
                    variant="outlined"
                    value={newStep.oQueFazer}
                    onChange={(e) => setNewStep({ ...newStep, oQueFazer: e.target.value })}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Departamento</InputLabel>
                    <Select
                      value={newStep.departamento}
                      onChange={(e) => setNewStep({ ...newStep, departamento: e.target.value })}
                    >
                      <MenuItem value="Financeiro">Financeiro</MenuItem>
                      <MenuItem value="TI">TI</MenuItem>
                      <MenuItem value="Recursos Humanos">Recursos Humanos</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Prazo para Vencimento (dias)"
                    variant="outlined"
                    type="number"
                    value={newStep.prazoVencimento}
                    onChange={(e) => setNewStep({ ...newStep, prazoVencimento: e.target.value })}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Prazo para Início (dias)"
                    variant="outlined"
                    type="number"
                    value={newStep.prazoInicio}
                    onChange={(e) => setNewStep({ ...newStep, prazoInicio: e.target.value })}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Prazo para Entrega (dias)"
                    variant="outlined"
                    type="number"
                    value={newStep.prazoEntrega}
                    onChange={(e) => setNewStep({ ...newStep, prazoEntrega: e.target.value })}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Responsável"
                    variant="outlined"
                    value={newStep.responsavel}
                    onChange={(e) => setNewStep({ ...newStep, responsavel: e.target.value })}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Ação Sim</InputLabel>
                    <Select
                      value={newStep.acaoSim}
                      onChange={(e) => setNewStep({ ...newStep, acaoSim: e.target.value })}
                    >
                      <MenuItem value={true}>Adicionar/Remover</MenuItem>
                      <MenuItem value={false}>N/A</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Ação Não</InputLabel>
                    <Select
                      value={newStep.acaoNao}
                      onChange={(e) => setNewStep({ ...newStep, acaoNao: e.target.value })}
                    >
                      <MenuItem value={true}>Retornar no Passo</MenuItem>
                      <MenuItem value={false}>N/A</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Button variant="contained" color="primary" onClick={handleAddOrUpdateStep} fullWidth>
                {editNodeId ? 'Salvar Alterações' : 'Adicionar Passo'}
              </Button>
            </Box>
          </Modal>

          {isListView ? (
            <Box mt={4}>
              <Typography variant="h6">Lista de Fluxos</Typography>
              <ul>
                {nodes.map((node) => (
                  <li key={node.id}>
                    <strong>{typeof node?.data?.label?.props?.children[0]?.props?.children === 'string' ? node?.data?.label?.props?.children[0]?.props?.children : 'Sem Descrição'}</strong> -{' '}
                    {typeof node?.data?.label?.props?.children[1]?.props?.children === 'string' ? node?.data?.label?.props?.children[1]?.props?.children.split(': ')[1] : 'Sem Código'}
                    <IconButton onClick={() => handleOpenModal(node.id)}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </li>
                ))}
              </ul>
            </Box>
          ) : (
            <Box mt={4} sx={{ height: 500, border: '1px solid #ddd' }}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                fitView
                style={{ width: '100%', height: '100%' }}
              >
                <Controls />
                <Background />
              </ReactFlow>
            </Box>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default WorkflowPage;
